// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`StudioNavigation should render 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 32px 16px;
  -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
  width: 320px;
}

.emotion-0>:not(style):not(style) {
  margin: 0;
}

.emotion-0>:not(style)~:not(style) {
  margin-top: 40px;
}

.emotion-1 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 4px 10px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: #F1ECF4;
  border-radius: 4px;
  color: #1C1B1FB3;
  height: 56px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  min-width: 0;
  padding-left: 8px;
  padding-right: 8px;
  gap: 8px;
}

.emotion-1::-moz-focus-inner {
  border-style: none;
}

.emotion-1.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-1 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-1:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-1:hover {
    background-color: #4B35FF;
  }
}

.emotion-1:active {
  box-shadow: none;
}

.emotion-1.Mui-focusVisible {
  box-shadow: none;
}

.emotion-1.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-1:hover {
  box-shadow: none;
}

.emotion-1.Mui-focusVisible {
  box-shadow: none;
}

.emotion-1:active {
  box-shadow: none;
}

.emotion-1.Mui-disabled {
  box-shadow: none;
}

.emotion-1 typography {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.emotion-1:hover {
  background-color: #D9D3DD;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-color: #F1ECF4;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
}

.emotion-3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Open Sans;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #FFFFFF;
  background-color: #bdbdbd;
  background-color: #010a4a;
}

.emotion-4 {
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}

.emotion-5 {
  display: inherit;
  margin-right: -2px;
  margin-left: 8px;
}

.emotion-5>*:nth-of-type(1) {
  font-size: 18px;
}

.emotion-6 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-7 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #1C1B1FB3;
  overflow-x: hidden;
  overflow-y: auto;
}

.emotion-9 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-width: 0;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-wrap: nowrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: inherit;
}

.emotion-9::-moz-focus-inner {
  border-style: none;
}

.emotion-9.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-9 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-9:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-9:hover {
    background-color: transparent;
  }
}

.emotion-9.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-9.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-9.Mui-selected:hover {
  background-color: rgba(75, 53, 255, 0.12);
}

@media (hover: none) {
  .emotion-9.Mui-selected:hover {
    background-color: rgba(75, 53, 255, 0.08);
  }
}

.emotion-9.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-9.Mui-disabled {
  opacity: 0.38;
}

.emotion-9:focus,
.emotion-9:hover {
  background-color: #F1ECF4;
  color: #47464F;
}

.emotion-9.active {
  background-color: #F3DBF8;
  color: #340042;
}

.emotion-9.active:focus,
.emotion-9.active:hover {
  background-color: #E1CDE6;
  color: #340042;
}

.emotion-10 {
  min-width: 56px;
  color: rgba(0, 0, 0, 0.54);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: inherit;
  margin-right: 8px;
  min-width: 0px;
  margin-right: 16px;
}

.emotion-11 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-12 {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.emotion-12 .MuiListItemText-secondary {
  color: inherit;
}

.emotion-13 {
  margin: 0;
  font: inherit;
  display: block;
}

.emotion-27 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
  margin-top: 16px;
  margin-bottom: 16px;
}

.emotion-28 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-width: 0;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-left: 16px;
  padding-right: 16px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-width: 0;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-wrap: nowrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: inherit;
}

.emotion-28::-moz-focus-inner {
  border-style: none;
}

.emotion-28.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-28 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-28:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-28:hover {
    background-color: transparent;
  }
}

.emotion-28.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-28.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-28.Mui-selected:hover {
  background-color: rgba(75, 53, 255, 0.12);
}

@media (hover: none) {
  .emotion-28.Mui-selected:hover {
    background-color: rgba(75, 53, 255, 0.08);
  }
}

.emotion-28.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-28.Mui-disabled {
  opacity: 0.38;
}

.emotion-28::-moz-focus-inner {
  border-style: none;
}

.emotion-28.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-28 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-28:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-28:hover {
    background-color: transparent;
  }
}

.emotion-28.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-28.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-28.Mui-selected:hover {
  background-color: rgba(75, 53, 255, 0.12);
}

@media (hover: none) {
  .emotion-28.Mui-selected:hover {
    background-color: rgba(75, 53, 255, 0.08);
  }
}

.emotion-28.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-28.Mui-disabled {
  opacity: 0.38;
}

.emotion-28:focus,
.emotion-28:hover {
  background-color: #F1ECF4;
  color: #47464F;
}

.emotion-28.active {
  background-color: #F3DBF8;
  color: #340042;
}

.emotion-28.active:focus,
.emotion-28.active:hover {
  background-color: #E1CDE6;
  color: #340042;
}

.emotion-41 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: absolute;
  bottom: 80px;
  left: 306px;
  z-index: 99999;
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
  width: 24px;
  height: 24px;
  background: #F1ECF4;
}

.emotion-41::-moz-focus-inner {
  border-style: none;
}

.emotion-41.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-41 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-41:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-41:hover {
    background-color: transparent;
  }
}

.emotion-41.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-42 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

<div
    class="MuiStack-root emotion-0"
    data-testid="studio-sidebar"
  >
    <button
      aria-haspopup="true"
      class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation emotion-1"
      data-testid="organization-select"
      tabindex="0"
      type="button"
    >
      <div
        class="MuiBox-root emotion-2"
      >
        <div
          class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault emotion-3"
          data-testid="initials-avatar"
        >
          T
        </div>
      </div>
      <span
        class="MuiTypography-root MuiTypography-labelMedium MuiTypography-noWrap emotion-4"
      >
        Test Organization
      </span>
      <span
        class="MuiButton-icon MuiButton-endIcon MuiButton-iconSizeSmall emotion-5"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-6"
          data-testid="UnfoldMoreIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M12 5.83 15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15z"
          />
        </svg>
      </span>
      <span
        class="MuiTouchRipple-root emotion-7"
      />
    </button>
    <nav
      class="MuiStack-root emotion-8"
    >
      <a
        aria-current="page"
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-9 active"
        href="/"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-10"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
            data-testid="CollectionsBookmarkOutlinedIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m-3 2v5l-1-.75L15 9V4zm3 12H8V4h5v9l3-2.25L19 13V4h1z"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-12"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-13"
          >
            Courses
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-7"
        />
      </a>
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-9"
        href="/studio/classrooms"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-10"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
            data-testid="SchoolIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M5 13.18v4L12 21l7-3.82v-4L12 17zM12 3 1 9l11 6 9-4.91V17h2V9z"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-12"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-13"
          >
            Classrooms
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-7"
        />
      </a>
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-9"
        href="/marketplace"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-10"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
            data-testid="StoreIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M20 4H4v2h16zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6zm-9 4H6v-4h6z"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-12"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-13"
          >
            Marketplace
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-7"
        />
      </a>
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-27"
      />
      <div
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-28"
        role="button"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-10"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
            data-testid="ContactSupportOutlinedIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M11 23.59v-3.6c-5.01-.26-9-4.42-9-9.49C2 5.26 6.26 1 11.5 1S21 5.26 21 10.5c0 4.95-3.44 9.93-8.57 12.4zM11.5 3C7.36 3 4 6.36 4 10.5S7.36 18 11.5 18H13v2.3c3.64-2.3 6-6.08 6-9.8C19 6.36 15.64 3 11.5 3m-1 11.5h2v2h-2zm2-1.5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-12"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-13"
          >
            Contact support
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-7"
        />
        <span
          class="MuiTouchRipple-root emotion-7"
        />
      </div>
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-9"
        href="/pricing"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-10"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
            data-testid="AttachMoneyIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-12"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-13"
          >
            Pricing
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-7"
        />
      </a>
    </nav>
    <button
      aria-label="Collapse Menu"
      class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-41"
      data-mui-internal-clone-element="true"
      data-testid="collapse-button"
      tabindex="0"
      type="button"
    >
      <svg
        aria-hidden="true"
        class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-42"
        data-testid="KeyboardArrowLeftIcon"
        focusable="false"
        viewBox="0 0 24 24"
      >
        <path
          d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6z"
        />
      </svg>
      <span
        class="MuiTouchRipple-root emotion-7"
      />
    </button>
  </div>
</DocumentFragment>
`;
