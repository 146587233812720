/* eslint-disable camelcase */
import React, { Suspense, useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { ThemeProvider } from '@mui/material/styles';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider } from '@tanstack/react-query';
import { CssBaseline } from '@mui/material';
import { IntercomProvider } from 'react-use-intercom';
import { WebSite } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
import { KyronStripe } from './utils/KyronStripe';
import { KyronIntercom } from './utils/KyronIntercom';
import { getTheme } from './theme/kyronTheme';
import './utils/i18n';
import './utils/dayjs';
import { getRoutes } from './Router';
import { Features, FeaturesProvider } from './FeaturesContext';
import { User, MainCredentials, KyronIntercomProp } from '../controllers/types';
import { queryClient } from '../controllers/react-query/config/queryClient';
import { getMainCredentials } from '../controllers/mainCredentials';

type Props = {
  user: User | null;
  features: Features;
  intercom_app_id: string;
};

function setAppTitle() {
  return (
    <Helmet
      title='Kyron Learning'
      script={[
        helmetJsonLdProp<WebSite>({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          name: 'Kyron Learning PBC',
          url: `${window.location.origin}`,
        }),
      ]}
      htmlAttributes={{ lang: 'en' }}
    />
  );
}

async function mainCredentials(user?: User | null) {
  try {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { ga_id, intercom }: MainCredentials = (await getMainCredentials()) as MainCredentials;

    if (ga_id)
      ReactGA.initialize(ga_id, {
        gtagOptions: {
          traffic_type: process.env.NODE_ENV === 'development' ? 'internal' : undefined,
          user_id: user?.id,
          user_properties: {
            organization_id: user?.active_organization?.id,
          },
        },
      });

    // need to initialize Intercom for development as well
    if (intercom) KyronIntercom.init(intercom);

    return intercom;
  } catch (error) {
    console.error('mainCredentials error: ', error);
    return null;
  }
}

// eslint-disable-next-line import/no-default-export
export default function Main(props: Props) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { user, features, intercom_app_id } = props;
  const [intercom, setIntercom] = useState<KyronIntercomProp | null>(null);

  useEffect(() => {
    mainCredentials(user).then(data => {
      if (data !== undefined) setIntercom(data);
    });
  }, [user]);

  // leaving out of the above block because the pricing table needs
  // to be initialized before the stripe element is rendered &
  // it's save to initialize Stripe locally
  KyronStripe.init();

  const routes = getRoutes(user);
  const router = createBrowserRouter(routes);

  return (
    <Suspense fallback='...is loading'>
      {setAppTitle()}
      {/* It appears like IntercomProvider doesn't work if the initialization
       has a null intercom_app_id. So ensure we have it */}
      <IntercomProvider
        autoBoot
        appId={intercom?.intercom_app_id || intercom_app_id}
        apiBase={intercom?.intercom_api_base || ''}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={getTheme()}>
            <SnackbarProvider maxSnack={3}>
              <FeaturesProvider features={features}>
                <CssBaseline />
                <RouterProvider router={router} />
              </FeaturesProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </IntercomProvider>
    </Suspense>
  );
}
