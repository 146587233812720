import { createContext, useContext, useEffect, useState } from 'react';
import { BackgroundTask } from '../controllers/types';
import { useUserNotifications } from '../channels/user_notifications';

export type AppState = {
  notifications: {
    backgroundTasks: Partial<{
      activeTasks: BackgroundTask[];
      failedTasks: BackgroundTask[];
    }>;
  };
};

type AppCtx = {
  appState: AppState;
  setAppState: (newState?: Partial<AppState> | ((prevState: AppState) => Partial<AppState>)) => void;
  notifications: AppState['notifications'];
};

export const AppContext = createContext({} as AppCtx);
export const useAppContext = () => useContext(AppContext);

/**
 * Initializes the app context
 */
export const useInitAppContext = () => {
  // APP STATE
  const [appState, $$setAppState] = useState<AppState>({
    notifications: { backgroundTasks: { activeTasks: [], failedTasks: [] } },
  });

  // For DEBUGGING
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (process.env.NODE_ENV === 'development') (window as any).appState = appState;

  // This is a wrapper to make sure the appState is always updated with the new state and never overridden entirely
  function setAppState(newState?: Partial<AppState> | ((prevState: AppState) => Partial<AppState>)) {
    // Handle the callback function case
    if (typeof newState === 'function') return $$setAppState(p => ({ ...p, ...newState(p) }));
    // Handle the object case
    $$setAppState(p => ({ ...p, ...newState }));
  }

  const data = useUserNotifications();
  const stringifiedData = JSON.stringify(data); // for deep comparison for change detection
  useEffect(setUserNotifications, [data, stringifiedData]);
  function setUserNotifications() {
    setAppState(prev => ({ notifications: { ...prev.notifications, ...data } }));
  }

  return {
    appState,
    notifications: appState.notifications,
    setAppState,
  } satisfies AppCtx;
};
