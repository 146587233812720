import React, { useState, useEffect, useRef } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  GlobalStyles,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ArrowBack, MoreVert } from '@mui/icons-material';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppBar } from 'components/AppBar/AppBar';
import { NoResources } from 'components/NoResources';
import { KyronMenu } from 'components/KyronMenu';
import { useFeatures } from 'components/FeaturesContext';
import {
  useLessonQuery,
  useLessonSectionsQuery,
  useLessonDetailsQuery,
  useGetLatestDraftLessonQuery,
} from 'controllers/react-query';
import { BackgroundTask } from 'controllers/types';
import { HelpButton } from 'components/HelpButton/HelpButton';
import { EditorSkeleton } from './EditorSkeleton';
import { EditorContext, useInitEditorContext } from './EditorContext';
import {
  getRegenerateAndNormalizeVideoBackgroundJob,
  getPublishBackgroundJob,
  getTranslateLessonBackgroundJob,
  getGenerateVideoBackgroundJob,
  useProgressBarChannel,
  generateSafeBGTaskErrorText,
  isPendingStatus,
} from '../../BackgroundTasks/useProgressBarChannel';
import { ErrorIndicator } from '../../ErrorIndicator';
import { LessonNameField } from '../components/LessonNameField';
import { PublishButton } from '../Publish/PublishButton';
import { useModal } from '../../utils/ModalContext';
import { Player } from '../../Player/Player';
import { queryClient } from '../../../controllers/react-query/config/queryClient';
import { LessonStatusTags } from './LessonStatusTags';
import { SectionList } from './SectionList';
import { DebugStreamsModal } from './DebugStreamsModal';
import { useLessonBTWatcher } from '../../BackgroundTasks/useLessonBTWatcher';
import { KyronEvents } from '../../utils/KyronEvents';

// export for testing
export function useLessonAndSectionsQuery(lessonId: number) {
  const { error: lessonError, isFetching: isLoadingLesson, data: lesson } = useLessonQuery(lessonId);
  const {
    error: sectionError,
    isFetching: isLoadingSections,
    data: lessonSectionsData,
  } = useLessonSectionsQuery(lessonId);
  const {
    error: segmentsError,
    isFetching: isLoadingSegments,
    data: lessonDetailsData,
  } = useLessonDetailsQuery(lessonId);
  const { lesson_sections: lessonSections = [] } = lessonSectionsData || {};
  const { lesson_segment_background_tasks: lessonSegmentBackgroundTasks } = lessonDetailsData || {};
  const isLoading = isLoadingLesson || isLoadingSections || isLoadingSegments;
  const errors: Error[] = [lessonError, sectionError, segmentsError].filter((e): e is Error => !!e);

  return { isLoading, errors, lesson, lessonSections, lessonSegmentBackgroundTasks };
}

export function useNavigateToNewDraft(publishStatus: string | null | undefined, lessonId: number) {
  const navigate = useNavigate();
  const [status, setStatus] = useState(publishStatus);

  const {
    error: draftError,
    isFetching: draftFetching,
    data: draftLesson,
    refetch,
  } = useGetLatestDraftLessonQuery(lessonId);

  if (publishStatus !== status && publishStatus === 'done') refetch();
  if (publishStatus !== status) setStatus(publishStatus);

  useEffect(() => {
    if (!draftFetching && !draftError && draftLesson && draftLesson.id !== lessonId) {
      navigate(`/studio/courses/${draftLesson.id}`, { replace: true });
    }
  }, [draftFetching, draftLesson]); // eslint-disable-line react-hooks/exhaustive-deps
}

export function Editor() {
  const isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));
  const { openModal } = useModal();
  const { lessonId } = useParams<{ lessonId: string }>();
  const location = useLocation();
  const {
    lesson,
    lessonSections,
    lessonSegmentBackgroundTasks,
    errors: sectionErrors,
  } = useLessonAndSectionsQuery(Number(lessonId));
  const editorContext = useInitEditorContext();
  const userMessageRef = useRef<HTMLDivElement>(null);

  // handle all background job statuses
  // 1- lesson creation
  // 2- lesson publishing
  // 3- lesson segment video generation
  function hasRunningBackgroundTasks(
    backgroundTask: BackgroundTask | undefined,
    progressStatus: string | undefined | null,
  ) {
    const isBackgroundTaskResultedInError = backgroundTask?.status === 'error';
    const isBackgroundTaskFinished = backgroundTask?.status === 'done' && progressStatus === undefined;
    const noBackgroundTaskInProgress = backgroundTask === undefined && progressStatus === undefined;
    const isArchived = backgroundTask?.status === 'archived' || progressStatus === 'archived';

    return !(
      noBackgroundTaskInProgress ||
      isBackgroundTaskFinished ||
      isBackgroundTaskResultedInError ||
      isArchived ||
      progressStatus === 'done'
    );
  }

  function invalidateLessonSegments(bt: BackgroundTask) {
    queryClient.invalidateQueries({ queryKey: [`/lesson_segments/${bt.task_entity_id}`] });
  }

  function invalidateLessonSections(bt: BackgroundTask) {
    queryClient.invalidateQueries({ queryKey: [`/lessons/${bt.task_entity_id}/lesson_sections`] });
  }

  const { activePlanningTasks, failedPlanningTasks, isPlanningInProgress } = useLessonBTWatcher(lessonId);

  const publishBackgroundJob = getPublishBackgroundJob(lesson);
  const publishTask = useProgressBarChannel(publishBackgroundJob, invalidateLessonSections);

  const publishingBackgroundTaskRunning = hasRunningBackgroundTasks(publishBackgroundJob, publishTask.status);

  const translationBackgroundJob = getTranslateLessonBackgroundJob(lesson);
  const translationTask = useProgressBarChannel(translationBackgroundJob);
  const translationBackgroundTaskRunning = hasRunningBackgroundTasks(translationBackgroundJob, translationTask.status);

  const regenerateAndNormalizeVideoBackgroundJob = getRegenerateAndNormalizeVideoBackgroundJob(lesson);
  const regenerateAndNormalizeVideoTask = useProgressBarChannel(regenerateAndNormalizeVideoBackgroundJob);
  console.debug('Editor: regenerateBT: ', regenerateAndNormalizeVideoBackgroundJob);
  const regenerateAndNormalizeVideoTaskRunning = hasRunningBackgroundTasks(
    regenerateAndNormalizeVideoBackgroundJob,
    regenerateAndNormalizeVideoTask.status,
  );

  const generateVideoBackgroundJob = getGenerateVideoBackgroundJob(lesson?.id || 0, lessonSegmentBackgroundTasks);
  const videoGenerationTask = useProgressBarChannel(generateVideoBackgroundJob, invalidateLessonSegments);
  const videoBackgroundTaskRunning = hasRunningBackgroundTasks(generateVideoBackgroundJob, videoGenerationTask.status);
  const { show_lesson_settings_button: showLessonSettingsButton } = useFeatures();

  const isCreatingLesson = lessonSections.length === 0 && isPlanningInProgress;
  useNavigateToNewDraft(publishTask.status, Number(lessonId));

  const publishError =
    publishTask.status === 'error' || publishBackgroundJob?.status === 'error'
      ? new Error(generateSafeBGTaskErrorText('publishing', publishTask))
      : null;

  const createError = failedPlanningTasks?.[0]
    ? new Error(generateSafeBGTaskErrorText('course creation', failedPlanningTasks[0]))
    : null;

  const videoGenerateError =
    videoGenerationTask.status === 'error'
      ? new Error(generateSafeBGTaskErrorText('video generation', videoGenerationTask))
      : null;

  const regenerateAndNormalizeVideoError =
    regenerateAndNormalizeVideoTask.status === 'error'
      ? new Error(generateSafeBGTaskErrorText('video generation', regenerateAndNormalizeVideoTask))
      : null;

  const translateError =
    translationTask.status === 'error' ? new Error(generateSafeBGTaskErrorText('translation', translationTask)) : null;

  const menuActions = (
    <>
      {showLessonSettingsButton && (
        <Button
          component={Link}
          to='settings'
          state={{ previousLocation: location }}
          variant='outlined'
          disabled={
            isPlanningInProgress ||
            publishingBackgroundTaskRunning ||
            videoBackgroundTaskRunning ||
            regenerateAndNormalizeVideoTaskRunning
          }
        >
          Settings
        </Button>
      )}
      <Button
        variant='outlined'
        disabled={isPlanningInProgress}
        onClick={() => {
          if (!lessonId) return;
          KyronEvents.sendEvent(KyronEvents.names.PREVIEW_CREATED_COURSE, { lesson_id: lessonId });
          openModal({
            fullWidth: true,
            themeMode: 'dark',
            id: 'draft-lesson-preview',
            title: 'Course Preview',
            content: (
              //  Just a note, same content can be reachable by going to the path: /video_player/${lessonId}?restart=true&force_separate=true
              <Box sx={{ position: 'relative', width: '100%', height: 'calc(80vh)', mb: 2 }}>
                <Player lessonId={lessonId} restart />
              </Box>
            ),
          });
        }}
      >
        Preview
      </Button>
      <PublishButton
        lesson={lesson}
        disabled={
          isPlanningInProgress ||
          publishingBackgroundTaskRunning ||
          videoBackgroundTaskRunning ||
          translationBackgroundTaskRunning ||
          regenerateAndNormalizeVideoTaskRunning
        }
      />
      <ErrorIndicator error={createError || videoGenerateError} />
      <ErrorIndicator error={publishError} description='Unable to publish course. Please try again.' />
    </>
  );

  const lessonNameField = lesson ? <LessonNameField lesson={lesson} /> : 'Loading...';

  const taskLoadingMessage = (message: string) => (
    <Stack direction='row' alignItems='center' gap={1}>
      <CircularProgress size={16} />
      {message}
    </Stack>
  );

  const taskErrorMessage = (err: Error, message: string) => (
    <Stack direction='row' alignItems='center' gap={1}>
      <ErrorIndicator error={err} />
      {message}
    </Stack>
  );

  const shouldShowUserMessage =
    isPlanningInProgress ||
    publishingBackgroundTaskRunning ||
    videoBackgroundTaskRunning ||
    regenerateAndNormalizeVideoTaskRunning ||
    translationBackgroundTaskRunning;

  const alertBox = (
    <Alert severity='info' data-testid='pending-task-info' ref={userMessageRef}>
      <AlertTitle>Processes in motion:</AlertTitle>
      {isPlanningInProgress && taskLoadingMessage(activePlanningTasks[0]?.message)}
      {createError && taskErrorMessage(createError, 'There was an error creating your course.')}
      {isPendingStatus(publishTask.status) && taskLoadingMessage('Publishing is in progress.')}
      {publishError && taskErrorMessage(publishError, 'There was an error publishing your course.')}
      {isPendingStatus(translationTask.status) && taskLoadingMessage('Translation is in progress.')}
      {translateError && taskErrorMessage(translateError, 'There was an error translating your course.')}
      {isPendingStatus(videoGenerationTask.status) && taskLoadingMessage('Video is being generated.')}
      {videoGenerateError && taskErrorMessage(videoGenerateError, 'There was an error generating your video.')}
      {isPendingStatus(regenerateAndNormalizeVideoTask.status) &&
        taskLoadingMessage('Lesson videos are being generated')}
      {regenerateAndNormalizeVideoError &&
        taskErrorMessage(regenerateAndNormalizeVideoError, 'There was an error generating videos')}
    </Alert>
  );

  // This effect will scroll the user message into view when it is shown
  useEffect(scrollUserMessageInView, [shouldShowUserMessage]);
  function scrollUserMessageInView() {
    if (shouldShowUserMessage && userMessageRef.current) {
      // optional chaining with ?. is NOT for here, they already have a check for current and scrollIntoView
      // is on the prototype of the current object. However, it is impossible to mock this logic here and for
      // testing purposes, I need to use optional chaining. If you remove them, you will realize that the tests
      // will fail.
      userMessageRef.current?.scrollIntoView?.({ behavior: 'smooth', inline: 'start', block: 'center' });
    }
  }

  if (!lessonId) return <NoResources message='No course selected' />;

  return (
    <EditorContext.Provider value={editorContext}>
      <GlobalStyles styles={theme => ({ body: { backgroundColor: theme.palette.background.paper } })} />
      <AppBar
        showLogo={false}
        title={isMobileView && lessonNameField}
        navButton={
          <>
            <IconButton to='/' component={Link}>
              <ArrowBack />
            </IconButton>
            <DebugStreamsModal />
          </>
        }
        actions={
          isMobileView ? (
            <KyronMenu menuIcon={<MoreVert />}>
              <Stack m={1} gap={1}>
                {menuActions}
              </Stack>
            </KyronMenu>
          ) : (
            menuActions
          )
        }
        mainToolBarSx={{ bgcolor: 'background.paper' }}
      />

      <Box px={2}>
        {shouldShowUserMessage && alertBox}

        {sectionErrors.length > 0 ? (
          <Stack gap={1} ml={2} mr={2}>
            {sectionErrors.map(error => (
              <Alert key={error.message} severity='error'>
                {error.message}
              </Alert>
            ))}
          </Stack>
        ) : null}
        <Stack sx={{ maxWidth: '1380px', m: '0 auto', px: 6, py: 8, gap: 1 }}>
          <LessonStatusTags lesson={lesson} />
          <Box>
            <Typography variant='displayMedium'>{lesson?.name}</Typography>
          </Box>
          <Box height={64} />
          {isCreatingLesson ? <EditorSkeleton /> : <SectionList />}
        </Stack>
      </Box>
      <HelpButton />
    </EditorContext.Provider>
  );
}
