import { Stack, Typography } from '@mui/material';
import React from 'react';

type CaseStudyCardProps = {
  description: string;
  quote: string;
  image: React.ReactNode;
  _path?: string;
};

export function CaseStudyCard({ description, quote, image, _path }: CaseStudyCardProps) {
  return (
    <Stack
      sx={{
        bgcolor: '#FFFFFF',
        px: { sm: '32px', xs: '24px' },
        py: { sm: '40px', xs: '32px' },
        borderRadius: { sm: 3, xs: 2 },
        gap: { sm: 3, xs: 2 },
      }}
    >
      {image}
      <Typography variant='bodyLarge'>{quote}</Typography>
      <Typography variant='titleMedium' pl={4}>
        {description}
      </Typography>
      {/* <Row gap={2}>
        <Button href={path}>Learn more</Button>
      </Row> */}
    </Stack>
  );
}
