import React, { ChangeEvent, useState } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useUpdateSegment } from 'controllers/react-query';
import { ACCEPT_IMAGE_TYPES, ACCEPT_VIDEO_TYPES, FileUploader, UploadButton } from 'components/Uploaders/FileUploader';
import { LessonSegment, SegmentMedia } from 'controllers/types';
import { FilePreview } from 'components/Uploaders/FilePreview';
import { ActiveStorageBlob } from 'components/Uploaders/types';
import { VideoRecorderButton } from 'components/Uploaders/VideoRecorderButton';
import { useFeatures } from 'components/FeaturesContext';
import { useEditorContext } from 'components/Studio/Editor/EditorContext';
import { KyronEvents } from 'components/utils/KyronEvents';

type Props = {
  // Pass segment as a prop because sometimes this is not the activeSegment, but a MC option
  segment: LessonSegment;
  isLoading?: boolean;
  columnLayout?: boolean;
  selectedMediaType: SegmentMedia;
  setSelectedMediaType: React.Dispatch<React.SetStateAction<SegmentMedia>>;
};

export function SegmentMediaSelection({
  segment,
  isLoading,
  columnLayout,
  selectedMediaType,
  setSelectedMediaType,
}: Props) {
  const { show_video_recorder: showVideoRecorder } = useFeatures();
  const { editorState } = useEditorContext();
  const { activeSectionId } = editorState;
  const { mutateAsync: updateSegment } = useUpdateSegment({
    segmentId: segment?.id,
    sectionId: activeSectionId,
  });
  const { image_url: imageUrl, video_url: videoUrl, media_type: currentMediaType } = segment;

  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [mediaTypeInProgress, setMediaTypeInProgress] = useState<SegmentMedia | null>(null);
  const [userVideoFile, setUserVideoFile] = useState<ActiveStorageBlob | null>(
    videoUrl && currentMediaType === SegmentMedia.USER_VIDEO
      ? { url: videoUrl, media_type: SegmentMedia.USER_VIDEO }
      : null,
  );
  const [imageFile, setImageFile] = useState<ActiveStorageBlob | null>(
    imageUrl ? { url: imageUrl, media_type: SegmentMedia.USER_IMAGE_WITH_AUDIO } : null,
  );

  if (!segment) {
    console.error('Segment not provided to SegmentMediaSelection');
    return null;
  }

  function handleSelect(_: ChangeEvent<HTMLInputElement>, value: string) {
    setSelectedMediaType(value as SegmentMedia);
  }

  async function attachVideoToSegment(blobs: ActiveStorageBlob[]) {
    const blob = blobs[0];
    if (!blob.signed_id) return;

    KyronEvents.sendEvent(KyronEvents.names.UPLOAD_SEGMENT_VIDEO, { segment_id: segment.id });
    setUserVideoFile(blob);
    setMediaTypeInProgress(SegmentMedia.USER_VIDEO);

    try {
      return await updateSegment({
        lessonId: segment.lesson_id,
        payload: { ...segment, video: blob.signed_id, media_type: SegmentMedia.USER_VIDEO },
      });
    } catch (e) {
      console.error('Failed to attach video', e);
      throw e;
    } finally {
      setMediaTypeInProgress(null);
    }
  }

  async function attachImageToSegment(blobs: ActiveStorageBlob[]) {
    const blob = blobs[0];
    if (!blob.signed_id) return;

    KyronEvents.sendEvent(KyronEvents.names.UPLOAD_SEGMENT_IMAGE, { segment_id: segment.id });
    setImageFile(blob);
    setMediaTypeInProgress(SegmentMedia.USER_IMAGE_WITH_AUDIO);

    try {
      return await updateSegment({
        lessonId: segment.lesson_id,
        payload: { ...segment, image_signed_id: blob.signed_id, media_type: SegmentMedia.USER_IMAGE_WITH_AUDIO },
      });
    } catch (e) {
      console.error('Failed to attach image', e);
      throw e;
    } finally {
      setMediaTypeInProgress(null);
    }
  }

  return (
    <Box>
      <Typography id='media-selection-radio-buttons-label' variant='titleSmall'>
        Media
      </Typography>
      <FormControl disabled={isLoading}>
        <RadioGroup
          aria-labelledby='media-selection-radio-buttons-label'
          name='media-selection-radio-buttons'
          value={selectedMediaType}
          onChange={handleSelect}
        >
          {/* KYRON_VIDEO OPTION */}
          <Stack direction={columnLayout ? 'column' : 'row'}>
            <FormControlLabel
              value={SegmentMedia.KYRON_VIDEO}
              componentsProps={{ typography: { variant: 'bodyMedium' } }}
              control={<Radio size='small' inputProps={{ 'aria-label': 'kyron video' }} />}
              label='Slide'
              sx={{ color: 'text.secondary' }}
            />
          </Stack>

          {/* USER VIDEO OPTION */}
          <Stack direction={columnLayout ? 'column' : 'row'}>
            <FormControlLabel
              value={SegmentMedia.USER_VIDEO}
              componentsProps={{ typography: { variant: 'bodyMedium' } }}
              control={<Radio size='small' inputProps={{ 'aria-label': 'user video' }} />}
              label='Video'
              sx={{ color: 'text.secondary' }}
            />
            {selectedMediaType === SegmentMedia.USER_VIDEO && (
              <FileUploader
                disabled={isLoading}
                fileTypeName='video'
                afterUpload={attachVideoToSegment}
                isAttachmentBeingProcessed={mediaTypeInProgress === SegmentMedia.USER_VIDEO}
                setFormDisabled={() => setIsFormDisabled(true)}
                acceptTypes={ACCEPT_VIDEO_TYPES}
                existingFile={userVideoFile}
              >
                <UploadButton
                  buttonLabel={userVideoFile ? 'Replace video' : 'Choose video'}
                  shouldBeDisabled={isFormDisabled || isLoading}
                />
                {showVideoRecorder ? (
                  <VideoRecorderButton buttonLabel='Record video' recordingScript={segment.description} />
                ) : null}
              </FileUploader>
            )}
          </Stack>

          {/* USER IMAGE OPTION */}
          <Stack direction={columnLayout ? 'column' : 'row'}>
            <FormControlLabel
              value={SegmentMedia.USER_IMAGE_WITH_AUDIO}
              componentsProps={{ typography: { variant: 'bodyMedium' } }}
              control={<Radio size='small' inputProps={{ 'aria-label': 'user image' }} />}
              label='Image'
              sx={{ color: 'text.secondary' }}
            />
            {selectedMediaType === SegmentMedia.USER_IMAGE_WITH_AUDIO && (
              <FileUploader
                disabled={isLoading}
                fileTypeName='image'
                afterUpload={attachImageToSegment}
                isAttachmentBeingProcessed={mediaTypeInProgress === SegmentMedia.USER_IMAGE_WITH_AUDIO}
                setFormDisabled={() => setIsFormDisabled(true)}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                existingFile={imageFile}
              >
                <FilePreview />
                <UploadButton
                  buttonLabel={imageFile ? 'Replace image' : 'Choose image'}
                  shouldBeDisabled={isFormDisabled || isLoading}
                />
              </FileUploader>
            )}
          </Stack>
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
