import React, { useState, useEffect, useRef, CSSProperties } from 'react';

import ReactPlayer from 'react-player';
import { PlayArrow as PlayArrowIcon, Pause as PauseIcon } from '@mui/icons-material';
import { Box, IconButton, SxProps } from '@mui/material';

import { styled } from '@mui/material/styles';
import { VideoPlayerControls } from 'components/Player/VideoPlayer/VideoPlayerControls';
import { OnProgressProps } from 'react-player/base';
import { LessonSegment } from 'controllers/types';
import { BaseReactPlayerProps } from 'react-player/types/base';
import { KyronEvents } from 'components/utils/KyronEvents';
import {
  VIDEO_INFO_VISIBLE_KEY,
  VIDEO_PLAYBACK_RATE_KEY,
  VIDEO_VOLUME_KEY,
  VIDEO_VOLUME_MUTED_KEY,
} from '../../../constants';

type StylesProps = { ProgressContainer: CSSProperties; PlayButton: CSSProperties };

const styles: StylesProps = {
  ProgressContainer: {
    position: 'fixed',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9999',
    justifyContent: 'center',
    alignItems: 'center',
  },
  PlayButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
  },
};

const PlayIconButton = styled(IconButton)({
  color: 'white',
  fontSize: '10vw',
  backgroundColor: 'rgba(0, 0, 0, 0.30)',
});

type LessonPlayer2Props = {
  runningLessonSegment: LessonSegment;
  segmentId: number;
  sessionId: number;
  setWaitTimeout: (waitTimeout: () => void) => void;
  cancelWaitTimeout: () => void;
  handleFullScreen: () => void;
  handleNextSegment: () => void;
  videoUrl: string;
  sx?: SxProps;
};

export function VideoPlayer({
  runningLessonSegment,
  segmentId,
  sessionId,
  setWaitTimeout,
  cancelWaitTimeout,
  handleFullScreen,
  handleNextSegment,
  videoUrl,
  sx,
}: LessonPlayer2Props) {
  // refs
  const playerRef = useRef<ReactPlayer>(null);
  const progressInterval = 100;

  // states
  const [controlsVisible, setControlsVisible] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [pauseVisible, setPauseVisible] = useState(true);
  const [playerHover, setPlayerHover] = useState(false);
  const [scrubberHover, setScrubberHover] = useState(false);
  const [scrubberPressed, setScrubberPressed] = useState(false);
  const [volumeHover, setVolumeHover] = useState(true);
  const [volumePressed, setVolumePressed] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [played, setPlayed] = useState(runningLessonSegment.start_time);
  const [muted, setMuted] = useState(false);

  // USER PREFERENCE STATES - should be in sync with session storage ------------------------
  const infoVisibleFromStorage = sessionStorage.getItem(VIDEO_INFO_VISIBLE_KEY) === 'true';
  const [infoVisible, setInfoVisible] = useState(infoVisibleFromStorage || false);

  const volumeMutedFromStorage = sessionStorage.getItem(VIDEO_VOLUME_MUTED_KEY) === 'true';
  const [volumeMuted, setVolumeMuted] = useState(volumeMutedFromStorage || false);

  const desiredPlaybackRateFromStorage = sessionStorage.getItem(VIDEO_PLAYBACK_RATE_KEY);
  const [playbackRate, setPlaybackRate] = useState(Number(desiredPlaybackRateFromStorage) || 1.0);

  const desiredVolumeFromStorage = volumeMutedFromStorage ? 0 : sessionStorage.getItem(VIDEO_VOLUME_KEY);
  const initialVolumeState = volumeMuted ? 0 : Number(desiredVolumeFromStorage) || 0.75;
  const [volume, setVolume] = useState(initialVolumeState);
  // END - USER PREFERENCE STATES -----------------------------------------------------------

  useEffect(() => {
    const timeoutId = setTimeout(() => setPauseVisible(false), 600);
    return () => clearTimeout(timeoutId);
  }, []);

  // This function handles the automatic pause timeout. If a student sits on a
  // wait_segment for more than time time_out value the lesson gets paused.
  //
  function handleWaitTimeout() {
    console.debug('handleWaitTimeout');
    KyronEvents.sendEvent(KyronEvents.names.WAIT_TIMEOUT, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: runningLessonSegment.name,
      played,
    });
    setPlaying(false);
    setInternalPlayerPlayPause(false);
  }

  // This function works in conjunction w/ handleWaitTimeout to set and reset
  // timeout between step and wait segments. Our timeouts only make sense when
  // we are looping on a wait_segment.
  //
  function handleTimeoutSetting() {
    if (!playing) return;
    if (runningLessonSegment.segment_type === 'wait') {
      setWaitTimeout(handleWaitTimeout);
    } else {
      cancelWaitTimeout();
    }
  }

  function setInternalPlayerPlayPause(play: boolean) {
    if (play) {
      console.debug('playing');
      if (played < runningLessonSegment.start_time) {
        playerRef.current?.seekTo(runningLessonSegment.start_time, 'seconds');
      }
      playerRef.current
        ?.getInternalPlayer()
        ?.play()
        .catch((e: DOMException) => {
          console.warn(`Cannot start video before user interacts the DOM. Error: ${e}`);
          setPlaying(false); // setting playing to false so state is correct
        });
    } else {
      console.debug('pausing');
      playerRef.current?.getInternalPlayer()?.pause();
    }
  }

  function setVideoPlayer(lessonSegment: LessonSegment) {
    KyronEvents.sendEvent(KyronEvents.names.PLAYING_SEGMENT, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: lessonSegment.name,
    });

    playerRef.current?.seekTo(lessonSegment.start_time, 'seconds');
    setPlayed(lessonSegment.start_time);
    setPlaying(true);
    setInternalPlayerPlayPause(true);
    console.debug(
      'setVideoPlayer: ',
      lessonSegment.segment_type,
      '--',
      lessonSegment.start_time,
      '::',
      lessonSegment.end_time,
    );

    if (lessonSegment.segment_type === 'wait') {
      setMuted(true);
    } else {
      setMuted(false);
    }
  }

  // handleEnd and handleProgress functionally do the same thing. The reason
  // we need both is because there are two types of lessons - compiled (single
  // video) lessons and uncompiled (multiple video files) lessons. When we are
  // running a compiled lesson handleProgress handles moving from one segment
  // to another and looping on wait_segments and when we are running an
  // uncompiled lesson handleEnd does the same.
  //
  function handleEnd() {
    handleTimeoutSetting();
    if (runningLessonSegment.segment_type === 'wait') {
      console.debug('handleEnd - wait loop -- ', played, '---', runningLessonSegment.end_time);
      setPlayed(runningLessonSegment.start_time);
      playerRef.current?.seekTo(runningLessonSegment.start_time, 'seconds');
    } else {
      console.debug('handleEnd: going to next segment');
      setPlaying(false);
      setInternalPlayerPlayPause(false);
      handleNextSegment();
    }
  }

  const handleProgress = (state: OnProgressProps) => {
    // handleProgress gets called even if the ReactPlayer is not playing
    // the video. so we don't want to do anything if nothing is playing
    if (!playing) return;
    if (!seeking) setPlayed(state.playedSeconds);
    if (playerRef.current && state.playedSeconds < runningLessonSegment.start_time) {
      playerRef.current.seekTo(runningLessonSegment.start_time, 'seconds');
    }
    if (playing && state.playedSeconds + progressInterval / 2000.0 >= runningLessonSegment.end_time) handleEnd();
  };

  // The internal state of the playing is not handled properly by the ReactPlayer,
  // so we are using event.player.isPlaying to check if the player is playing or not
  // before we start the video.
  const handleReady = (event: BaseReactPlayerProps) => {
    if (!event.player.isPlaying) {
      console.debug('handleReady when player is not playing');
      setInternalPlayerPlayPause(true);
    }
  };

  //----------------------------------------------------------------------------
  // The next group of functions all handle video control states.
  //
  const handleControlsToggle = (toggle: boolean) => {
    setVolumeHover(false);
    setControlsVisible(true);
    if (volume === 0) {
      setVolumeMuted(true);
      setVolumePressed(false);
    }
    if (toggle) {
      setControlsVisible(true);
    } else if (!scrubberPressed) {
      setControlsVisible(false);
    }
  };

  const handleSetPlaybackRate = (e: React.MouseEvent<HTMLButtonElement>) => {
    KyronEvents.sendEvent(KyronEvents.names.CHANGE_PLAYBACK_RATE, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: runningLessonSegment.name,
      rate: e.currentTarget.value,
    });
    e.stopPropagation();
    let desiredPlaybackRate = 1.0;
    if (e.currentTarget.value === '1') {
      desiredPlaybackRate = 1.5;
    } else if (e.currentTarget.value === '1.5') {
      desiredPlaybackRate = 2.0;
    } else if (e.currentTarget.value === '2') {
      desiredPlaybackRate = 1.0;
    }
    setPlaybackRate(desiredPlaybackRate);
    // set the playback rate in session storage for user preferences
    sessionStorage.setItem(VIDEO_PLAYBACK_RATE_KEY, desiredPlaybackRate.toString());
  };

  const handleScrubberCommitted = () => {
    handleSeekMouseUp();
    setScrubberPressed(false);
    setScrubberHover(false);
    if (!playerHover) {
      setControlsVisible(false);
    }
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e: Event, v: number | number[]) => {
    // Going to not handle array of seek as our logic is not built around it.
    if (Array.isArray(v)) {
      return;
    }
    KyronEvents.sendEvent(KyronEvents.names.USE_SCRUBBER, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: runningLessonSegment.name,
      seek_to: v + runningLessonSegment.start_time,
    });
    setPlayed(v + runningLessonSegment.start_time);
  };

  function handleSeekMouseUp() {
    setSeeking(false);
    playerRef.current?.seekTo(Math.floor(played), 'seconds');
  }

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    KyronEvents.sendEvent(KyronEvents.names.CLICK_BACK, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: runningLessonSegment.name,
    });
    setPlayed(runningLessonSegment.start_time);
    playerRef.current?.seekTo(runningLessonSegment.start_time, 'seconds');
  };

  const handleVolumeChange = (e: Event) => {
    const target = e.target as HTMLTextAreaElement;
    KyronEvents.sendEvent(KyronEvents.names.CHANGE_VOLUME, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: runningLessonSegment.name,
      volume_to: parseFloat(target.value) / 100,
    });
    const desiredVolume = parseFloat(target.value) / 100;
    setVolume(desiredVolume);
    // storing in session storage for user preferences
    sessionStorage.setItem(VIDEO_VOLUME_KEY, desiredVolume.toString());
  };

  const handleVolumeIconClick = () => {
    const newVolumeMutedState = !volumeMuted;
    setVolumeMuted(newVolumeMutedState);

    // set the volume
    const desiredVol = Number(sessionStorage.getItem(VIDEO_VOLUME_KEY)) || 0.75;
    setVolume(newVolumeMutedState ? 0 : desiredVol);

    // storing in session storage for user preferences
    sessionStorage.setItem(VIDEO_VOLUME_MUTED_KEY, newVolumeMutedState.toString());
  };

  const handleVolumeOnChangeCommitted = () => {
    setVolumePressed(false);
    if (volume === 0 && !volumeMuted) setVolumeMuted(true);
    setVolumeHover(false);
  };

  const handlePlayPause = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    KyronEvents.sendEvent(KyronEvents.names.CLICK_PLAY_PAUSE, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: runningLessonSegment.name,
      playing,
      played,
    });
    setPauseVisible(true);
    setTimeout(() => setPauseVisible(false), 600);
    setInternalPlayerPlayPause(!playing);
    setPlaying(!playing);
  };

  const handleInfoClick = () => {
    const newValue = !infoVisible;
    setInfoVisible(newValue);
    // store this in session storage for persisting user preferences
    sessionStorage.setItem(VIDEO_INFO_VISIBLE_KEY, newValue.toString());
  };
  //---------------------------------------------------------------------------

  const handlePlay = () => {
    handleTimeoutSetting();
  };

  useEffect(() => {
    setVideoPlayer(runningLessonSegment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningLessonSegment.id]);

  //* *************************************************************************&
  if (runningLessonSegment.video_url === null) {
    return <div />;
  }
  const videoDuration =
    runningLessonSegment.end_time === 99999
      ? Math.floor(runningLessonSegment.video_duration)
      : Math.round(runningLessonSegment.end_time - runningLessonSegment.start_time);

  const videoControls = (
    <>
      <VideoPlayerControls
        controlsVisible={controlsVisible}
        playing={playing}
        handlePlayPause={handlePlayPause}
        handleBack={handleBack}
        handleVolumeIconClick={handleVolumeIconClick}
        volumeHover={volumeHover}
        setVolumeHover={setVolumeHover}
        volumeMuted={volumeMuted}
        setVolumeMuted={setVolumeMuted}
        volumePressed={volumePressed}
        setVolumePressed={setVolumePressed}
        handleVolumeChange={handleVolumeChange}
        handleVolumeOnChangeCommitted={handleVolumeOnChangeCommitted}
        volume={volume}
        playbackRate={playbackRate}
        handleSetPlaybackRate={handleSetPlaybackRate}
        scrubberHover={scrubberHover}
        setScrubberHover={setScrubberHover}
        setScrubberPressed={setScrubberPressed}
        handleScrubberCommitted={handleScrubberCommitted}
        handleSeekChange={handleSeekChange}
        handleSeekMouseDown={handleSeekMouseDown}
        videoDuration={videoDuration}
        played={played}
        handleInfoClick={handleInfoClick}
        infoVisible={infoVisible}
        handleFullScreen={handleFullScreen}
        runningLessonSegment={runningLessonSegment}
      />
      {/* Play/Pause button in center of video */}
      <div style={styles.PlayButton}>
        {pauseVisible && (
          <PlayIconButton aria-label='play or pause' onClick={handlePlayPause}>
            {!playing ? <PlayArrowIcon fontSize='inherit' /> : <PauseIcon fontSize='inherit' />}
          </PlayIconButton>
        )}
      </div>
    </>
  );

  return (
    <>
      {/* video screen */}
      <Box
        onClick={handlePlayPause}
        onMouseEnter={() => {
          handleControlsToggle(true);
          setPlayerHover(true);
        }}
        onMouseLeave={() => {
          handleControlsToggle(false);
          setPlayerHover(false);
        }}
        sx={{ position: 'relative', ...sx }}
      >
        <ReactPlayer
          key={sessionId}
          ref={playerRef}
          controls={false}
          url={videoUrl}
          // Never pass a truthy value here! See a `useEffect` earlier in this
          // file for more details.
          playing={false}
          playbackRate={playbackRate}
          progressInterval={progressInterval}
          playsinline
          volume={volume}
          muted={muted}
          loop={false}
          onPlay={handlePlay}
          onReady={handleReady}
          onProgress={handleProgress}
          onEnded={handleEnd}
          width='100%'
          height='100%'
        />
        {videoControls}
      </Box>
      {/* This is for mabl testing */}
      <span hidden id='forMABL'>
        {runningLessonSegment.name}
      </span>
    </>
  );
}
