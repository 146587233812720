import { Divider, Stack, SxProps } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { NavItemButton, NavItemButtonProps } from 'components/NavItemButton/NavItemButton';
import {
  AccessTimeOutlined,
  AccountCircleOutlined,
  HistoryOutlined,
  HomeOutlined,
  MenuBook,
} from '@mui/icons-material';
import { useUserContext } from 'components/UserContext';
import KyronLogo from '../../../assets/kyron_logo.svg';

const styles: Record<string, SxProps> = {
  navItem: {
    borderRadius: '8px',
    '&.active': {
      bgcolor: 'surfaceContainer.main',
      color: 'surfaceContainer.contrastText',
    },
  },
};

export function MarketplaceNav({ sx }: { sx?: SxProps }) {
  const { user } = useUserContext();

  const marketplaceNavItems: NavItemButtonProps[] = [
    { label: 'Home', icon: <HomeOutlined />, to: '/marketplace', disabled: false },
    { label: 'History', icon: <HistoryOutlined />, to: '/history', disabled: true },
    { label: 'Watch later', icon: <AccessTimeOutlined />, to: '/watch-later', disabled: true },
  ];

  const libraryNavItem = { label: 'Your courses', icon: <MenuBook />, to: '/', disabled: false };

  const channelId = user?.active_organization?.channel?.id;
  const channelNavItem = channelId
    ? {
        label: 'Your channel',
        icon: <AccountCircleOutlined />,
        to: `channels/${channelId}`,
        disabled: false,
      }
    : null;

  const navItems = user
    ? [...marketplaceNavItems, { divider: true }, libraryNavItem, channelNavItem]
    : [...marketplaceNavItems];

  return (
    <Stack gap={5} sx={{ p: { sm: '22px 0 22px 16px', xs: '22px 0 22px 8px' }, ...sx }}>
      <Link to='/' style={{ display: 'flex' }} data-testid='app-bar-logo'>
        <img alt='Kyron Learning' src={KyronLogo} height='24' />
      </Link>

      <Stack spacing={0.5} component='nav' sx={{ typography: 'labelLarge', color: 'text.secondary' }}>
        {navItems.map(item => {
          if (!item) return null;
          if ('divider' in item) return <Divider key={Math.random()} />;
          return (
            <NavItemButton
              key={item.label}
              to={item.to}
              label={item.label}
              icon={item.icon}
              sx={styles.navItem}
              disabled={item.disabled}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
