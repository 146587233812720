import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Divider, Stack, Typography, TextField, Tab, Tabs, Link } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getLessonCollectionURL, getLTILink } from 'components/utils/urlUtils';
import {
  usePublishLesson,
  useLessonValidateQuery,
  useDownloadScorm,
  useGetLtiPlatforms,
} from 'controllers/react-query';
import { KyronIntercom } from 'components/utils/KyronIntercom';
import { PublishVisibility, Lesson } from 'controllers/types';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { CopyButton } from 'components/CopyButton';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useUserContext } from 'components/UserContext';
import { ValidationsDisplay, ValidationErrors } from './ValidationsDisplay';

const DEFAULT_URL = 'https://www.kyronlearning.com';

type ExportCourseDialogProps = {
  lesson?: Lesson;
  onClose: () => void;
  isProcessingCourse?: boolean;
};
export function ExportCourseDialog({ lesson, onClose, isProcessingCourse }: ExportCourseDialogProps) {
  const {
    id: lessonId,
    published_lesson_collection: lessonCollection, // only expose if lesson is published
    has_unpublished_changes: hasUnpublishedChanges,
  } = lesson || {};

  const {
    data: lessonValidations,
    refetch: refetchValidations,
    isFetching: loadingValidations,
    error: fetchValidationError,
  } = useLessonValidateQuery(lessonId);
  const { isPending: isPublishPending, error: publishLessonError, mutate: publishLesson } = usePublishLesson();

  const publishAndCloseDialog = () => {
    if (lessonId) {
      KyronEvents.sendEvent(KyronEvents.names.PUBLISH_COURSE, { lesson_id: lessonId });
      publishLesson({ lessonId, payload: { visibility: PublishVisibility.PRIVATE } }, { onSuccess: onClose });
    }
  };

  const failingValidations =
    lessonValidations?.filter(
      validation => !validation.pass && validation.name !== ValidationErrors.UNNORMALIZED_VIDEOS,
    ) || [];
  const hasFailingValidations = failingValidations.length > 0;
  useEffect(() => {
    refetchValidations?.();
  }, [refetchValidations]);

  return (
    <Stack p={2} data-testid='export-course-dialog'>
      <Typography variant='bodyMedium' mb={2}>
        Export your course so you can share it with students. You can share your course as a link, by downloading a
        SCORM zip file to upload in your LMS, or with an LTI integration in your LMS.
      </Typography>

      {/* Publish */}
      <Button
        onClick={publishAndCloseDialog}
        disabled={
          isProcessingCourse ||
          isPublishPending ||
          loadingValidations ||
          hasFailingValidations ||
          !hasUnpublishedChanges
        }
        startIcon={
          isPublishPending || loadingValidations || isProcessingCourse ? <LoadingIndicator spinnerSize={20} /> : null
        }
        data-testid='publish-button'
      >
        {lessonCollection ? 'Republish' : 'Publish'}
      </Button>
      {hasUnpublishedChanges ? undefined : (
        <Typography variant='bodySmall' color='text.secondary' align='center'>
          Course is up-to-date
        </Typography>
      )}
      {isProcessingCourse ? (
        <Typography variant='bodySmall' color='text.secondary' align='center'>
          Course is processing
        </Typography>
      ) : undefined}

      {/* Validations */}
      {fetchValidationError && (
        <Box mt={2}>
          <Alert severity='error'>{fetchValidationError.message}</Alert>
        </Box>
      )}
      {publishLessonError && (
        <Box mt={2}>
          <Alert severity='error'>{publishLessonError.message}</Alert>
        </Box>
      )}
      <ValidationsDisplay lessonId={lessonId} failingValidations={failingValidations} disabled={isProcessingCourse} />

      <ShareOptions lesson={lesson} onClose={onClose} />
    </Stack>
  );
}

function ShareTabPanel({ tabValue, index, children }: { tabValue: number; index: number; children: React.ReactNode }) {
  return (
    <Box hidden={tabValue !== index} aria-label={`tab-option-${index}`} id={`tab-${index}`} role='tabpanel'>
      {tabValue === index && (
        <Stack gap={1} minHeight={200}>
          {children}
        </Stack>
      )}
    </Box>
  );
}

function ShareOptions({ lesson, onClose }: { lesson?: Lesson; onClose: () => void }) {
  const [tabValue, setTabValue] = useState(0);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const {
    id: lessonId,
    published_lesson_collection: lessonCollection, // only expose if lesson is published
  } = lesson || {};
  const ltiLink = lessonCollection ? getLTILink(lessonCollection) : DEFAULT_URL;
  const collectionUrl = lessonCollection ? getLessonCollectionURL(lessonCollection.id) : DEFAULT_URL;

  const { mutateAsync: getScormFile, isPending: isDownloadingScorm } = useDownloadScorm(lessonId || 0);

  const { data: ltiPlatformData } = useGetLtiPlatforms(1, 1); // just check if there's at least one set up
  const hasSetupLTI = (ltiPlatformData?.lti_platforms?.length ?? 0) > 0;

  const handleLtiSettingsClick = () => {
    onClose();
    navigate('/studio/settings/lti_platforms');
  };

  const handleScormDownload = async () => {
    try {
      await getScormFile();
      KyronEvents.sendEvent(KyronEvents.names.DOWNLOAD_SCORM, {
        lesson_collection_id: lessonCollection?.id,
        lesson_name: lessonCollection?.name,
        user_id: user?.id,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Stack gap={3} data-testid='share-options'>
      <Divider sx={{ my: 2 }} />
      <Box width='100%'>
        <Tabs
          value={tabValue}
          onChange={(_event, newValue) => setTabValue(newValue)}
          aria-label='Share course options'
          variant='fullWidth'
        >
          <Tab label='Link' />
          <Tab label='SCORM' />
          <Tab label='LTI' />
        </Tabs>
      </Box>
      <ShareTabPanel tabValue={tabValue} index={0}>
        <Typography variant='labelMedium'>Link</Typography>
        <Typography variant='bodyMedium'>Share this link with students.</Typography>
        <Stack direction='row' spacing={1} alignItems='center'>
          <TextField
            fullWidth
            value={collectionUrl}
            disabled={!lessonCollection}
            onFocus={e => e.target.select()}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <CopyButton
                  disabled={!lessonCollection}
                  value={collectionUrl}
                  onAnimationEnd={() =>
                    KyronEvents.sendEvent(KyronEvents.names.LINK_SHARE, {
                      lesson_collection_id: lessonCollection?.id,
                      lesson_name: lessonCollection?.name,
                      user_id: user?.id,
                    })
                  }
                />
              ),
            }}
          />
        </Stack>
        <Typography variant='bodySmall'>Note, all student activity will be anonymous and non-reportable.</Typography>
      </ShareTabPanel>
      <ShareTabPanel tabValue={tabValue} index={1}>
        <Typography variant='labelMedium'>SCORM</Typography>
        <Typography variant='bodySmall' color='text.secondary'>
          Download SCORM package and upload it to your LMS.
        </Typography>
        <Button
          variant='outlined'
          startIcon={isDownloadingScorm ? <LoadingIndicator /> : <Download />}
          disabled={!lessonCollection || isDownloadingScorm}
          onClick={handleScormDownload}
        >
          Download SCORM
        </Button>
        <Typography variant='bodySmall' color='text.secondary'>
          Note, all student activity will be anonymous and non-reportable.
        </Typography>
      </ShareTabPanel>
      <ShareTabPanel tabValue={tabValue} index={2}>
        {!hasSetupLTI && (
          <Alert severity='error' icon={false} sx={{ borderRadius: 1 }} data-testid='lti-warning'>
            <Stack gap={1}>
              <Typography>
                Before you can share via LTI link, you need to set up an LTI integration between Kyron and your LMS.{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  component='button'
                  onClick={() => KyronIntercom.showArticle('lti')}
                  sx={{ verticalAlign: 'bottom' }}
                >
                  Learn more here.
                </Link>
              </Typography>
              <Button variant='outlined' onClick={handleLtiSettingsClick}>
                Go to Organization LTI Settings
              </Button>
            </Stack>
          </Alert>
        )}
        <Typography variant='labelMedium'>LTI</Typography>
        <Typography variant='bodyMedium'>
          Use an LTI link to integrate with your LMS. {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link component='button' onClick={() => KyronIntercom.showArticle('lti')} sx={{ verticalAlign: 'bottom' }}>
            Learn more.
          </Link>
        </Typography>
        <Stack direction='row' spacing={1} alignItems='center'>
          <TextField
            fullWidth
            value={ltiLink}
            disabled={!lessonCollection || !hasSetupLTI}
            onFocus={e => e.target.select()}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <CopyButton
                  value={ltiLink}
                  disabled={!lessonCollection}
                  onAnimationEnd={() =>
                    KyronEvents.sendEvent(KyronEvents.names.LTI_LINK_SHARE, {
                      lesson_collection_id: lessonCollection?.id,
                      lesson_name: lessonCollection?.name,
                      user_id: user?.id,
                    })
                  }
                />
              ),
            }}
          />
        </Stack>
      </ShareTabPanel>
    </Stack>
  );
}
