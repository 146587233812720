import { Check } from '@mui/icons-material';
import { Box, ListItemButton, Stack, Typography } from '@mui/material';
import { Organization } from 'controllers/types';
import React from 'react';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { useUserContext } from '../UserContext';
import { KyronImage } from '../KyronImage/KyronImage';

interface OrganizationSelectItemProps {
  active?: boolean;
  organization: Organization;
  onClick: () => void;
}

export function OrganizationSelectItem({ active = false, organization, onClick }: OrganizationSelectItemProps) {
  const userContext = useUserContext();
  return (
    <ListItemButton
      sx={{
        height: 64,
        px: 2,
        gap: 1,
        '&:hover, &.Mui-selected:hover': { bgcolor: 'surface.dark' },
        '&:last-of-type': { borderBottom: 'none' },
      }}
      onClick={onClick}
      data-testid={`org-menu-item-${organization.id}`}
      tabIndex={0} // make list items tabbable for keyboard navigation
      divider
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 48,
          height: 48,
          borderRadius: 1,
          bgcolor: 'surfaceContainer.main',
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        {organization.logo ? (
          <KyronImage stretch src={organization.logo} alt={organization.name} loading='lazy' />
        ) : (
          <KyronAvatar label={organization.name} />
        )}
      </Box>

      <Stack minWidth={0} flexGrow={1}>
        <Typography variant='bodyMedium' color='text.primary' noWrap>
          {organization.name}
        </Typography>
        <Typography variant='bodySmall' color='text.secondary'>
          {organization.member_count} members
        </Typography>
        {userContext.isPlatformAdmin && (
          <Typography variant='bodySmall' color='text.secondary' noWrap>
            ID: {organization.slug}
          </Typography>
        )}
      </Stack>
      <Box sx={{ width: 24, height: 24, flexGrow: 0, flexShrink: 0 }}>
        {active ? <Check color='primary' data-testid='active-check' sx={{ flexGrow: 0, flexShrink: 0 }} /> : null}
      </Box>
    </ListItemButton>
  );
}
