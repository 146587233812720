import React, { useState } from 'react';
import { Button, Chip, Stack, Link, TableRow, TableCell, Typography, TextField, Box, styled } from '@mui/material';
import { Share, Delete, BugReport, Assignment, Check, PlayCircleOutline, MultipleStop } from '@mui/icons-material';
import KyronIcon from 'assets/kyronicon-small.svg';
import { useFeatures } from 'components/FeaturesContext';
import { KyronMenu } from 'components/KyronMenu';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorIndicator } from 'components/ErrorIndicator';
import {
  useWatchDraftLessonTasks,
  useWatchPublishedLessonTasks,
} from 'components/BackgroundTasks/useProgressBarChannel';
import { getLessonCollectionURL, getLTILink } from 'components/utils/urlUtils';
import { Lesson, LessonCollection } from 'controllers/types';
import { useDeleteLessonCollection } from 'controllers/react-query/lessonCollectionHooks';
import { useUserContext } from 'components/UserContext';
import { ShareDialog } from 'components/ShareDialog';
import { ClassroomSelector } from 'components/CatalogLessonCard/ClassroomSelector';
import { ReauthenticateDialog } from 'components/ReauthenticateDialog';
import { useQueryClient } from '@tanstack/react-query';
import { useModal } from 'components/utils/ModalContext';
import { KyronImage } from 'components/KyronImage/KyronImage';
import { enqueueSnackbar } from 'notistack';
import { isEmpty, startCase, toLower } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { KyronMenuItem } from 'components/KyronMenuItem';
import { KyronIntercom } from '../../../utils/KyronIntercom';
import { Row } from '../../../Row/Row';
import { CopyButton } from '../../../CopyButton';
import { KyronEvents } from '../../../utils/KyronEvents';

// This adds "disabled" state styles on a table row toggled via the "disabled" prop.
const RowWithDisable = styled(TableRow, {
  shouldForwardProp: prop => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  opacity: disabled ? 0.5 : 1,
  backgroundColor: disabled ? theme.palette.action.disabledBackground : 'inherit',
  '& > *': {
    color: disabled ? theme.palette.text.disabled : 'inherit',
  },
  userSelect: disabled ? 'none' : 'auto',
  cursor: disabled ? 'not-allowed' : 'inherit',
  pointerEvents: disabled ? 'none' : 'auto',
}));

export const useWatchLessonStatus = (draftLesson?: Lesson, publishedLesson?: Lesson) => {
  const lessonQueryClient = useQueryClient();
  const [watchingLessonIds, setWatchingLessonIds] = useState<number[]>([]);
  const { isInProgress, error, warning } = useWatchDraftLessonTasks(draftLesson);
  const { isInProgress: isTranslating, warning: translationWarning } = useWatchPublishedLessonTasks(publishedLesson);

  if (isInProgress && draftLesson && !watchingLessonIds.includes(draftLesson.id)) {
    setWatchingLessonIds(prev => [...prev, draftLesson.id]);
  }

  if (isTranslating && publishedLesson && !watchingLessonIds.includes(publishedLesson.id)) {
    setWatchingLessonIds(prev => [...prev, publishedLesson.id]);
  }

  if (!isInProgress && draftLesson && watchingLessonIds.includes(draftLesson.id)) {
    lessonQueryClient.invalidateQueries({ queryKey: ['/lessons'] });
    setWatchingLessonIds(prev => prev.filter(id => id !== draftLesson.id));
  }

  if (!isTranslating && publishedLesson && watchingLessonIds.includes(publishedLesson.id)) {
    lessonQueryClient.invalidateQueries({ queryKey: ['/lessons'] });
    setWatchingLessonIds(prev => prev.filter(id => id !== publishedLesson.id));
  }

  return {
    isInProgress: isInProgress || isTranslating,
    error,
    warning: warning || translationWarning,
  };
};

type LibraryListItemProps = {
  lessonCollection: LessonCollection;
  variant?: 'card' | 'row';
};
export function LibraryListItem({ lessonCollection, variant = 'row' }: LibraryListItemProps) {
  const { isPlatformAdmin, user } = useUserContext();

  // Studio library should have only one lesson variant in the lesson collection, so will always pick the first one.
  const draftLesson = lessonCollection.draft_lessons?.[0];
  const publishedLesson = lessonCollection.lessons?.find(lesson => lesson.locale === 'en');
  const hasPublishedLesson = !isEmpty(lessonCollection.lessons);
  const publishedLessonLink = hasPublishedLesson ? getLessonCollectionURL(lessonCollection.id) : '';
  const { isInProgress, error, warning } = useWatchLessonStatus(draftLesson, publishedLesson);
  const shouldDisableLessonLink = !hasPublishedLesson || isInProgress || Boolean(error);
  const isPrivateLesson = lessonCollection.visibility === 'PRIVATE';

  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const [openAssignDialog, setOpenAssignDialog] = React.useState(false);
  const [showReauthenticateDialog, setShowReauthenticateDialog] = React.useState(false);

  const { openModal, closeModal } = useModal();
  const { mutateAsync: deleteLessonCollection, isPending: isDeleting } = useDeleteLessonCollection();

  function openDeleteModal() {
    const handleDelete = () => {
      if (!lessonCollection)
        return console.error('Lesson deletion error: No lesson collection ID found when trying to delete.');
      deleteLessonCollection({ lessonCollectionId: lessonCollection.id })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response.code === 0) {
            enqueueSnackbar(`Deleted Lesson ${lessonCollection.name}.`);
          } else if (response.code === 1) {
            enqueueSnackbar(`Warning: Active Lesson ${lessonCollection.name} deleted.`);
          }
        })
        .catch((err: Error) => {
          console.error(err);
          enqueueSnackbar(`Error deleting lesson ${lessonCollection.name}`, { variant: 'error' });
        });
      closeModal();
    };

    openModal({
      id: 'delete-lesson',
      title: 'Delete Lesson',
      content: 'Are you sure you want to delete this lesson?',
      action: (
        <Stack direction='row' spacing={2}>
          <Button variant='outlined' onClick={closeModal}>
            Cancel
          </Button>
          <Button color='error' onClick={handleDelete}>
            Delete
          </Button>
        </Stack>
      ),
    });
  }

  const handleLTILinkCopy = () => {
    const ltiLink = getLTILink(lessonCollection);
    openModal({
      id: 'get-lti-link',
      title: 'LTI Link',
      content: (
        <Stack spacing={2}>
          <Typography>
            Use this link to setup LTI connection to the lesson. Follow{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link component='button' onClick={() => KyronIntercom.showArticle('lti')} sx={{ verticalAlign: 'bottom' }}>
              Kyron LTI 1.3 documentation
            </Link>{' '}
            for more details.
          </Typography>
          <Row gap={1} justifyContent='space-between' width='100%'>
            <TextField
              variant='outlined'
              size='small'
              fullWidth
              defaultValue={ltiLink}
              inputProps={{ readOnly: true }}
            />
            <CopyButton
              value={ltiLink}
              label='Copy'
              onAnimationEnd={() =>
                KyronEvents.sendEvent(KyronEvents.names.LTI_LINK_SHARE, {
                  lesson_collection_id: lessonCollection.id,
                  lesson_name: lessonCollection.name,
                  user_id: user?.id,
                })
              }
            />
          </Row>
        </Stack>
      ),
    });
  };

  const editPath = draftLesson?.use_df
    ? `/studio/internal_lesson_editor/${draftLesson.id}/edit`
    : `/studio/courses/${draftLesson?.id}`;

  const visibility = startCase(toLower(hasPublishedLesson ? lessonCollection.visibility : 'DRAFT'));

  const hasUnpublishedChanges = Boolean(draftLesson?.has_unpublished_changes);

  const menu = (
    <KyronMenu>
      <KyronMenuItem
        data-testid='lesson-start-button'
        icon={<PlayCircleOutline />}
        label='Go to published lesson'
        component={RouterLink}
        to={publishedLessonLink}
        target='_blank'
        disabled={shouldDisableLessonLink}
      />

      <KyronMenuItem
        data-testid='lesson-share-button'
        icon={<Share />}
        label='Share'
        onClick={() => setOpenShareDialog(true)}
        disabled={shouldDisableLessonLink || isPrivateLesson}
      />

      <KyronMenuItem
        data-testid='copy-lti-link-button'
        icon={<MultipleStop />}
        label='Get LTI Link'
        onClick={handleLTILinkCopy}
        disabled={shouldDisableLessonLink}
      />

      <KyronMenuItem
        data-testid='assign-lesson-button'
        icon={<Assignment />}
        label='Assign lesson'
        onClick={() => setOpenAssignDialog(true)}
        disabled={shouldDisableLessonLink}
      />

      {isPlatformAdmin && (
        <KyronMenuItem
          data-testid='lesson-edit-debug-button'
          icon={<BugReport />}
          label='Debug'
          component={RouterLink}
          to={`/studio/internal_lesson_editor/${draftLesson?.id}/edit`}
          disabled={isInProgress}
        />
      )}

      <KyronMenuItem
        data-testid='lesson-delete-button'
        icon={<Delete />}
        label='Delete lesson'
        onClick={openDeleteModal}
        sx={{ color: 'error.main' }}
        disabled={draftLesson?.use_df}
      />
    </KyronMenu>
  );

  const shareDialog = (
    <ShareDialog
      open={openShareDialog}
      onClose={() => setOpenShareDialog(false)}
      quote={`Check out this lesson I created on Kyron Learning, ${lessonCollection.name}!`}
      emailMessage={`I made a lesson on Kyron Learning called ${lessonCollection.name}, and I wanted to share it with you. Click the link to check it out!`}
      link={publishedLessonLink}
      eventResource={{ lesson_collection_id: lessonCollection.id, lesson_name: lessonCollection.name }}
    />
  );

  const assignDialog = hasPublishedLesson && (
    <>
      <ClassroomSelector
        open={openAssignDialog}
        close={() => setOpenAssignDialog(false)}
        lessonCollection={lessonCollection}
        reauthenticate={() => {
          setShowReauthenticateDialog(true);
          setOpenAssignDialog(false);
        }}
      />
      <ReauthenticateDialog open={showReauthenticateDialog} onClose={() => setShowReauthenticateDialog(false)} />
    </>
  );

  const getStatusIcon = () => {
    if (error || warning) {
      const message = error || warning || undefined;
      return <ErrorIndicator error={new Error(message)} severity={error ? 'error' : 'warning'} />;
    }
    const shouldSpin = isInProgress || isDeleting;
    return <LoadingIndicator loading={shouldSpin} />; // returns null if !shouldSpin
  };

  const updatedAt = dayjs(draftLesson?.updated_at).fromNow();

  return (
    <>
      {variant === 'card' ? (
        <ListItemCard
          lessonCollection={lessonCollection}
          updatedAt={updatedAt}
          editPath={editPath}
          actions={menu}
          hasUnpublishedChanges={hasUnpublishedChanges}
          statusIcon={getStatusIcon()}
          visibility={visibility}
          disabled={isDeleting}
        />
      ) : (
        <ListItemRow
          lessonCollection={lessonCollection}
          updatedAt={updatedAt}
          editPath={editPath}
          visibility={visibility}
          hasUnpublishedChanges={hasUnpublishedChanges}
          statusIcon={getStatusIcon()}
          menu={menu}
          disabled={isDeleting}
          hasError={Boolean(error)}
        />
      )}

      {shareDialog}

      {assignDialog}
    </>
  );
}

const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.primaryContainer.main};
  box-shadow: 1;
  border-radius: 20px;
  font-weight: bold;
`;

type ListItemCardProps = {
  lessonCollection: LessonCollection;
  updatedAt: string;
  visibility?: string;
  editPath: string;
  actions: React.ReactNode;
  statusIcon: React.ReactNode;
  disabled: boolean;
  hasUnpublishedChanges: boolean;
};
function ListItemCard({
  lessonCollection,
  updatedAt,
  visibility,
  editPath,
  actions,
  statusIcon,
  disabled,
  hasUnpublishedChanges,
}: ListItemCardProps) {
  const { new_plg_flow: isNewPlgFlow } = useFeatures();

  return (
    <Stack sx={{ opacity: disabled ? 0.5 : 1 }}>
      <Box sx={{ position: 'relative' }}>
        {isNewPlgFlow ? null : (
          // Visibility/Change status icons - hidden for new PLG flow
          <Row sx={{ position: 'absolute', top: 8, left: 8 }} gap={1}>
            {visibility && <StyledChip label={visibility} />}
            {hasUnpublishedChanges && <StyledChip label='Has unpublished changes' />}
          </Row>
        )}
        <RouterLink to={editPath} style={{ textDecoration: 'none' }}>
          <KyronImage
            stretch
            src={lessonCollection.collection_thumbnail}
            fallbackSrc={KyronIcon}
            alt='lesson collection thumbnail'
            dataTestID='lesson-thumbnail'
          />
        </RouterLink>
      </Box>
      <Row spacing={2} alignItems='center' justifyContent='space-between'>
        <Stack flex={1}>
          <Typography
            variant='titleSmall'
            component={RouterLink}
            to={editPath}
            sx={{ textDecoration: 'none', color: 'inherit' }}
          >
            {lessonCollection.name}
          </Typography>
          <Typography variant='bodySmall'>Updated {updatedAt}</Typography>
        </Stack>
        <Row spacing={1}>
          {statusIcon}
          {isNewPlgFlow ? null : actions}
        </Row>
      </Row>
    </Stack>
  );
}

type ListItemRowProps = {
  lessonCollection: LessonCollection;
  updatedAt: string;
  visibility: string;
  editPath: string;
  hasUnpublishedChanges: boolean;
  statusIcon: React.ReactNode;
  menu: React.ReactNode;
  disabled: boolean;
  hasError: boolean;
};
function ListItemRow({
  lessonCollection,
  updatedAt,
  hasUnpublishedChanges,
  editPath,
  visibility,
  statusIcon,
  menu,
  disabled,
  hasError,
}: ListItemRowProps) {
  const { new_plg_flow: isNewPlgFlow } = useFeatures();
  return (
    <RowWithDisable disabled={disabled}>
      <TableCell width={56}>
        <Box sx={{ maxWidth: 56 }}>{statusIcon}</Box>
      </TableCell>

      <TableCell sx={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        <Link
          component={RouterLink}
          to={editPath}
          sx={{ color: `${hasError ? 'error.main' : 'primary.main'}` }}
          data-testid='lesson-edit-link'
        >
          {lessonCollection.name}
        </Link>
      </TableCell>

      {/* TODO(derick): cleanup after moving all users to new PLG flow */}
      {isNewPlgFlow ? null : (
        <>
          <TableCell width={96} sx={{ maxWidth: '96px' }}>
            {visibility}
          </TableCell>
          <TableCell width={180} sx={{ maxWidth: '180px' }}>
            {hasUnpublishedChanges && <Check />}
          </TableCell>
        </>
      )}

      <TableCell width={140} sx={{ maxWidth: '140px' }}>
        {updatedAt}
      </TableCell>

      {/* TODO(derick): cleanup after moving all users to new PLG flow */}
      {isNewPlgFlow ? null : (
        <TableCell width={72} sx={{ maxWidth: '72px' }}>
          {menu}
        </TableCell>
      )}
    </RowWithDisable>
  );
}
