import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Input,
  Link,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Row } from 'components/Row/Row';
import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import { ArrowForward } from '@mui/icons-material';
import { KyronLogo } from 'components/KyronLogo';
import { useIntercom } from 'react-use-intercom';
import { UserContext } from 'components/UserContext';
import { useGenerateEntireLesson, useTitlesQuery } from 'controllers/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import speed from 'assets/speed.png';
import interactivity from 'assets/interactivity.png';
import analytics from 'assets/analytics.png';
import wgu from 'assets/wgu.png';
import kipp from 'assets/kipp.png';
import baltimore from 'assets/baltimore.png';
import relay from 'assets/relay.png';
import { useGetLessonCollections } from 'controllers/react-query/lessonCollectionHooks';
import { sampleSize } from 'lodash';
import { useVisibleGridCols } from 'components/utils/visibleGridColumns';
import { LessonCollection } from 'controllers/types';
import { Helmet } from 'react-helmet';
import { KyronEvents } from 'components/utils/KyronEvents';
import { ExamplePromptCard } from './components/ExamplePromptCard';
import { ValuePropBlock } from './components/ValuePropBlock';
import { CaseStudyCard } from './components/CaseStudyCard';
import { HomepageSection } from './components/HomepageSection';
import { HomepageWrapper } from './components/HomepageWrapper';
import { HomepageHeadline } from './components/HomepageHeadline';
import { CosmicGradient } from './components/CosmicGradient';
import { MarketingHeader } from './components/MarketingHeader';
import { HomepageCourseCard } from './components/HomepageCourseCard';
import { SAMPLE_TITLES } from './titles';

export function useTitlePrompts(
  count: number,
  learningEnvironment?: string,
): { titles: string[]; isFetching: boolean } {
  const shouldFetchFromBackend = Boolean(learningEnvironment);

  // Fetch titles from the backend if learningEnvironment is provided
  const { data: backendTitles, isFetching } = useTitlesQuery(count, shouldFetchFromBackend, learningEnvironment);

  // If backend titles are available and not currently fetching, use them
  const titles = useMemo(() => {
    if (!isFetching && backendTitles?.length) {
      return backendTitles;
    }

    // Use random titles from the sample array if backend call is disabled or fails
    return sampleSize(SAMPLE_TITLES, count);
  }, [count, backendTitles, isFetching]);

  return { titles, isFetching };
}

const valueProps = [
  {
    headline: 'Accelerate Course Development with AI',
    subhead: 'Accelerated Development',
    description:
      'Revolutionize course development by automating the generation of interactive, pedagogically sound videos, dialogues and more. Our intuitive editor helps you achieve significant time and cost savings and enables you to offer anything from short lessons to multi-week courses at a fraction of the time and cost.',
    image: <img src={speed} alt='Accelerated development' width='100%' />,
  },
  {
    headline: 'Truly Interactive Courses',
    subhead: 'Engaging Interactivity',
    description:
      "Create a personalized and guided learning journey anchored by discussions that immediately address learners' misconceptions. Keep learners on track while providing a safe learning environment.",
    image: <img src={interactivity} alt='Engaging interactivity' width='100%' />,
  },
  {
    headline: 'Gain Insight and Drive Efficiency',
    subhead: 'Analytics',
    description:
      'Advanced analytics give you actionable insights on learning trends that enhance instructional strategies and improve learning outcomes, helping you maximize impact. ',
    image: <img src={analytics} alt='Analytics' width='100%' />,
  },
];

const caseStudies = [
  {
    image: <img src={wgu} alt='WGU logo' width={160} />,
    description: 'Dr. Joe Dery, Dean of Data Analytics, Computer Science, and Software Engineering, WGU',
    quote:
      '“In early testing, we’ve seen really positive reactions to the Kyron lessons that we’ve piloted. By providing students with instructor-curated, interactive content on-demand, especially when live human support may not be readily available, we have the potential to really drive stronger course completion rates, which means more students on their way to a timely graduation. It’s really exciting.”',
  },
  {
    image: <img src={kipp} alt='KIPP logo' width={160} />,
    description: 'April Goble, Executive Director, KIPP Chicago Schools',
    quote:
      '“The Kyron Studio beta puts the power of AI-assisted instructional content creation in the hands of our teachers. The fact that the teacher is able to review and edit how the AI interacts with our students is important, and it helps teachers feel more confident about using AI in the classroom.”',
  },
  {
    image: <img src={baltimore} alt='Baltimore City Public Schools logo' width={160} />,
    description: 'John Davis, Chief of Schools, Baltimore City Public Schools',
    quote:
      '“Kyron is an excellent partner to provide math resources for teachers and students. Their lessons utilize excellent teachers aligned with state standards in an AI environment. They consistently want and act on feedback, strive to make their lessons teacher and student friendly, and are open and thoughtful with how AI is being implemented in a classroom environment.”',
    path: '/case-study',
  },
  {
    image: <img src={relay} alt='Relay GSE logo' width={160} />,
    description: 'Mayme Hostetter, President, Relay GSE',
    quote:
      '“Kyron has been a great partner in building virtual experiences for our students. Their team is innovative, considerate and invested in making improvements that matter for the entire learning journey.”',
    path: '/case-study',
  },
];

export function MarketingHome() {
  const gridRef = useRef<HTMLDivElement>(null);
  const numberOfGridColumns = useVisibleGridCols(gridRef);
  const { hash } = useLocation();
  const { showSpace } = useIntercom();
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  // useTitlePrompts can optionally take in a learning environment to generate titles
  // from the backend. This is useful for showing relevant prompts based on the user's context.
  const { titles: examplePrompts, isFetching } = useTitlePrompts(3);
  const [titlePrompt, setTitlePrompt] = useState('');
  const { user } = useContext(UserContext);
  const { isPending, mutate: createLesson } = useGenerateEntireLesson();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { data: lessonCollections } = useGetLessonCollections({ featured: true });
  const previewCourses: LessonCollection[] = lessonCollections?.slice(0, numberOfGridColumns * 2) || [];

  const footerLinks = [
    {
      category: 'Product',
      links: [
        { label: 'Help', onClick: () => showSpace('help') },
        { label: 'Pricing', href: '/pricing' },
        { label: 'Contact us', href: '/contact-us' },
      ],
    },
    {
      category: 'Company',
      links: [
        // { label: 'About', href: '/about' },
        { label: 'Terms of service', href: '/terms_of_service' },
        { label: 'Privacy policy', href: '/privacy_policy' },
      ],
    },
    {
      category: 'Follow us',
      links: [
        { label: 'Blog', href: 'https://blog.kyronlearning.com/' },
        { label: 'LinkedIn', href: 'https://www.linkedin.com/company/kyronlearning/' },
        { label: 'Twitter', href: 'https://twitter.com/kyronlearning' },
        { label: 'Instagram', href: 'https://www.instagram.com/kyronlearning/' },
      ],
    },
  ];

  useEffect(scrollToAnchor, [hash]);
  function scrollToAnchor() {
    if (hash) document.querySelector(hash)?.scrollIntoView();
  }

  const courseFromTitlePayload = {
    lessonTitle: titlePrompt,
    lessonObjective: titlePrompt, // Send the title as the objectives, which will get refined through the structured learning objectives process
    audience: 'College Students',
  };

  function handleCreateLesson() {
    if (user) {
      KyronEvents.sendEvent(KyronEvents.names.CREATE_COURSE_FROM_TITLE);
      createLesson(
        { payload: courseFromTitlePayload },
        {
          onSuccess: lesson => {
            navigate(`/studio/courses/${lesson.id}`);
            enqueueSnackbar(`Your lesson ${lesson.name} is being created. This may take a few minutes.`, {
              variant: 'success',
            });
          },
          onError: error => {
            enqueueSnackbar('Failed to create lesson', { variant: 'error' });
            console.error(error.message);
          },
        },
      );
    } else {
      window.location.href = `/login?course_title=${encodeURIComponent(titlePrompt)}`;
    }
  }

  return (
    <>
      <Helmet>
        <link rel='canonical' href='https://www.kyronlearning.com/' />
      </Helmet>
      <MarketingHeader />

      <HomepageSection pt={isMobile ? '72px' : '90px'} bgcolor='#FFF5EC'>
        <HomepageWrapper gap={isMobile ? '72px' : '90px'}>
          <Stack gap='36px' maxWidth='960px'>
            <Typography
              variant='displayLarge'
              sx={{ fontSize: { sm: '64px', xs: '40px' }, lineHeight: { sm: '70px', xs: '48px' } }}
            >
              Create and deliver engaging online courses at a fraction of the time and cost
            </Typography>
            {/* <Typography
              variant='displayMedium'
              component='h2'
              sx={{
                fontSize: { sm: '40px', xs: '32px' },
                lineHeight: { sm: '48px', xs: '40px' },
                fontWeight: 'normal',
                maxWidth: '680px',
              }}
            >
              Build anything from a 10-minute lesson to an 8-week course
            </Typography> */}
          </Stack>

          <Stack gap={4} maxWidth='600px'>
            <Typography
              variant='bodyLarge'
              sx={{
                fontSize: { sm: '24px', xs: '19px' },
                lineHeight: { sm: '32px', xs: '27px' },
                color: 'text.secondary',
              }}
            >
              Build anything from a 10-minute to a 10-week interactive course in minutes, with engaging visuals and
              rooted in pedagogically sound best practices.
            </Typography>
            <Row gap={2}>
              <Button
                component={RouterLink}
                to='/create'
                onClick={() => KyronEvents.sendEvent(KyronEvents.names.CREATE_FREE_COURSE)}
              >
                Create a course for free
              </Button>
            </Row>
          </Stack>

          <Typography variant='titleLarge' sx={{ color: 'text.secondary' }}>
            Preview a course.
          </Typography>
        </HomepageWrapper>

        <Stack mt={2} sx={{ position: 'relative', zIndex: 1, px: 3 }}>
          <Box
            sx={{
              width: 'calc(100% + 96px)',
              marginLeft: '-48px',
            }}
          >
            <Box
              sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', gap: 3 }}
              ref={gridRef}
            >
              {previewCourses.map(lessonCollection => (
                <HomepageCourseCard key={lessonCollection.id} course={lessonCollection} />
              ))}
            </Box>
          </Box>
        </Stack>

        <CosmicGradient sx={{ position: 'absolute', left: 'calc(50% + 145px)', top: '159px', zIndex: 0 }} />
      </HomepageSection>

      <HomepageSection pt={isMobile ? '80px' : '130px'} pb={isMobile ? '80px' : '130px'} bgcolor='#FAFCFF' id='builder'>
        <HomepageWrapper maxWidth='950px' gap={isMobile ? '64px' : '130px'}>
          <HomepageHeadline subhead='Fast creation, easy customization'>Build your first course today</HomepageHeadline>

          <Stack gap={3}>
            <Input
              type='text'
              name='learning_objective'
              placeholder='Develop learning content in minutes, then customize it for your needs'
              value={titlePrompt}
              onChange={e => setTitlePrompt(e.target.value)}
              disableUnderline
              data-testid='course-title-input'
              sx={{
                height: '72px',
                fontSize: '20px',
                borderRadius: 3,
                bgcolor: '#FFFFFF',
                border: 'none',
                px: { sm: 3, xs: 2 },
                gap: 1,
                boxShadow:
                  '0px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34), 0px 1.1px 1.8px -0.8px rgba(162, 147, 169, 0.34), 0px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34), 0px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34)',
              }}
              endAdornment={
                <IconButton
                  color='primary'
                  variant='filled'
                  disabled={titlePrompt === ''}
                  onClick={handleCreateLesson}
                  data-testid='create-course-from-title'
                >
                  {isPending ? <CircularProgress size={24} color='inherit' /> : <ArrowForward />}
                </IconButton>
              }
            />

            <Row
              sx={{
                gap: { sm: 3, xs: 0 },
                alignItems: 'stretch',
                overflowX: { sm: 'hidden', xs: 'auto' },
                mx: { sm: '0', xs: '-16px' },
                '&::-webkit-scrollbar': { display: 'none' },
              }}
            >
              {examplePrompts.map((prompt, index) => (
                <ExamplePromptCard
                  key={prompt}
                  loading={isFetching}
                  prompt={prompt}
                  id={index.toString()}
                  onClick={setTitlePrompt}
                  sx={{
                    flex: { sm: 1, xs: '0 0 75%' },
                    mr: { sm: '0', xs: '16px' },
                    ':first-of-type': { ml: { sm: '0', xs: '16px' } },
                  }}
                />
              ))}
            </Row>
          </Stack>
        </HomepageWrapper>
        <CosmicGradient
          variant='nebula'
          sx={{ position: 'absolute', left: 'calc(50% - 410px)', bottom: '-320px', zIndex: 0 }}
        />
      </HomepageSection>

      <HomepageSection
        pt={isMobile ? '80px' : '160px'}
        pb={isMobile ? '80px' : '160px'}
        bgcolor='#FAF7F9'
        id='benefits'
      >
        <HomepageWrapper gap={isMobile ? '64px' : '130px'}>
          <HomepageHeadline subhead='Key benefits of online course creation'>Why use Kyron?</HomepageHeadline>

          {valueProps.map(({ headline, subhead, description, image }) => (
            <ValuePropBlock headline={headline} subhead={subhead} image={image} key={headline}>
              {description}
            </ValuePropBlock>
          ))}
        </HomepageWrapper>
        <CosmicGradient
          variant='forest'
          sx={{ position: 'absolute', left: 'calc(50% - 1000px)', top: '-100px', zIndex: 0 }}
        />
        <CosmicGradient
          variant='forest'
          sx={{ position: 'absolute', left: 'calc(50% + 300px)', bottom: '-100px', zIndex: 0 }}
        />
      </HomepageSection>

      <HomepageSection
        pt={isMobile ? '80px' : '130px'}
        pb={isMobile ? '80px' : '130px'}
        bgcolor='#03317B'
        id='customers'
      >
        <HomepageWrapper gap='90px'>
          <HomepageHeadline color='#E5E1E6' subhead='Customers'>
            Don’t take our word for it
          </HomepageHeadline>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                sm: 'repeat(auto-fill, minmax(480px, 1fr))',
                xs: 'repeat(auto-fill, minmax(100%, 1fr))',
              },
              gap: '32px',
            }}
          >
            {caseStudies.map(({ description, quote, image }) => (
              <CaseStudyCard description={description} quote={quote} image={image} key={quote} />
            ))}
          </Box>
        </HomepageWrapper>

        <CosmicGradient
          variant='nebula'
          sx={{ position: 'absolute', left: 'calc(50% - 410px)', top: '-400px', zIndex: 0 }}
        />
      </HomepageSection>

      <HomepageSection pt={isMobile ? '80px' : '160px'} pb={isMobile ? '80px' : '160px'} bgcolor='#460052'>
        <HomepageWrapper maxWidth='800px' gap='120px'>
          <Stack sx={{ gap: 2, maxWidth: '800px', m: '0 auto', textAlign: 'center' }}>
            <Typography
              variant='displayLarge'
              sx={{ color: '#FFDED1', fontSize: { sm: '72px', xs: '40px' }, lineHeight: { sm: '78px', xs: '48px' } }}
            >
              Join innovative course creators in creating engaging online lessons
            </Typography>
            {/* <Typography
              variant='headlineLarge'
              sx={{
                color: '#F7D0FC',
                fontSize: { sm: '48px', xs: '32px' },
                lineHeight: { sm: '56px', xs: '40px' },
                fontWeight: 'normal',
              }}
            >
              Try it for free
            </Typography> */}
          </Stack>

          <Row
            sx={{
              gap: { sm: 3, xs: 6 },
              alignItems: { sm: 'flex-start', xs: 'center' },
              flexDirection: { sm: 'row', xs: 'column' },
            }}
          >
            <Box flex={isMobile ? 1 : '0 0 240px'}>
              <KyronLogo fill='#EAB9D1' height='20px' />
            </Box>

            {footerLinks.map(({ category, links }) => (
              <Stack
                gap={isMobile ? 1 : 4}
                flex={isMobile ? '0 0 auto' : 1}
                textAlign={isMobile ? 'center' : 'left'}
                key={category}
              >
                <Typography variant='titleMedium' color='#EAB9D1'>
                  {category}
                </Typography>
                <Stack gap={isMobile ? '8px' : '12px'}>
                  {links.map(({ label, href, onClick }) => {
                    if (onClick) {
                      return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                          key={label}
                          variant='bodyLarge'
                          color='#E5E1E6'
                          onClick={onClick}
                          sx={{ cursor: 'pointer' }}
                          tabIndex={0}
                        >
                          {label}
                        </Link>
                      );
                    }
                    return (
                      <Link variant='bodyLarge' component={RouterLink} to={href} color='#E5E1E6' key={label}>
                        {label}
                      </Link>
                    );
                  })}
                </Stack>
              </Stack>
            ))}
          </Row>

          <Typography variant='bodyMedium' textAlign='center' color='#F4AEFF'>
            © 2024 Kyron Learning. All rights reserved.
          </Typography>
        </HomepageWrapper>

        <CosmicGradient
          variant='ocean'
          sx={{ position: 'absolute', left: 'calc(50% - 526px)', top: '-280px', zIndex: 0 }}
        />
      </HomepageSection>
    </>
  );
}
