// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`GeneralSettings renders correctly 1`] = `
<DocumentFragment>
  .emotion-0 {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 80px;
  padding-top: 16px;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
}

.emotion-3 {
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.emotion-4 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
  background-color: #FFFFFF;
}

.emotion-5 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px 0 5px;
  padding-top: 1px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  padding: 8.5px 14px;
}

.emotion-5.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-5:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-5:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-5.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-5.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-5.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-6 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 0;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: auto;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 0;
  resize: none;
  padding: 0;
}

.emotion-6::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-6::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-6:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-6::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-6:focus {
  outline: 0;
}

.emotion-6:invalid {
  box-shadow: none;
}

.emotion-6::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-6.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-6:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-6:-webkit-autofill {
  border-radius: inherit;
}

.emotion-8 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-9 {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0;
  line-height: 11px;
  -webkit-transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-10 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

.emotion-22 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.emotion-23 {
  border: 1px solid;
  border-color: #D6D5DD;
  border-radius: 8px;
  width: 320px;
  height: 180px;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.emotion-23:hover .upload-button {
  opacity: 1;
}

.emotion-24 {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
}

.emotion-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-26 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
}

.emotion-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 600px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-28 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: #F1ECF4;
  color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
  min-width: 156px;
  opacity: 0;
}

.emotion-28::-moz-focus-inner {
  border-style: none;
}

.emotion-28.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-28 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-28:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(28, 27, 31, 0.04);
}

@media (hover: none) {
  .emotion-28:hover {
    background-color: transparent;
  }
}

.emotion-28.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-28:hover {
  box-shadow: none;
}

.emotion-28.Mui-focusVisible {
  box-shadow: none;
}

.emotion-28:active {
  box-shadow: none;
}

.emotion-28.Mui-disabled {
  box-shadow: none;
}

.emotion-28:hover,
.emotion-28:focus {
  background-color: #E3DFFF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
}

.emotion-28.Mui-disabled {
  background-color: #FCFAFD;
}

.emotion-29 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-29>*:nth-of-type(1) {
  font-size: 20px;
}

.emotion-30 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-31 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

.emotion-32 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-35 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(186, 26, 26, 0.5);
  color: #BA1A1A;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-35::-moz-focus-inner {
  border-style: none;
}

.emotion-35.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-35 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-35:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(186, 26, 26, 0.04);
  border: 1px solid #BA1A1A;
}

@media (hover: none) {
  .emotion-35:hover {
    background-color: transparent;
  }
}

.emotion-35.Mui-disabled {
  color: #1C1B1F61;
  border: 1px solid #1C1B1F1F;
}

.emotion-35:hover {
  box-shadow: none;
}

.emotion-35.Mui-focusVisible {
  box-shadow: none;
}

.emotion-35:active {
  box-shadow: none;
}

.emotion-35.Mui-disabled {
  box-shadow: none;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <label
          class="MuiTypography-root MuiTypography-labelMedium emotion-3"
          for="lesson-title"
        >
          Title
        </label>
        <div
          class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-4"
          data-testid="lesson-title"
        >
          <div
            class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall MuiInputBase-multiline emotion-5"
          >
            <textarea
              aria-invalid="false"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiInputBase-inputSizeSmall emotion-6"
              id="lesson-title"
              style="height: 0px; overflow: hidden;"
            >
              Lesson 1
            </textarea>
            <textarea
              aria-hidden="true"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiInputBase-inputSizeSmall emotion-6"
              readonly=""
              style="visibility: hidden; position: absolute; overflow: hidden; height: 0px; top: 0px; left: 0px; transform: translateZ(0); padding-top: 0px; padding-bottom: 0px; width: 100%;"
              tabindex="-1"
            />
            <fieldset
              aria-hidden="true"
              class="MuiOutlinedInput-notchedOutline emotion-8"
            >
              <legend
                class="emotion-9"
              >
                <span
                  class="notranslate"
                >
                  ​
                </span>
              </legend>
            </fieldset>
          </div>
        </div>
      </div>
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-10"
      />
      <div
        class="MuiStack-root emotion-2"
      >
        <label
          class="MuiTypography-root MuiTypography-labelMedium emotion-3"
          for="lesson-description"
        >
          Description
        </label>
        <div
          class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-4"
          data-testid="lesson-description"
        >
          <div
            class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall MuiInputBase-multiline emotion-5"
          >
            <textarea
              aria-invalid="false"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiInputBase-inputSizeSmall emotion-6"
              id="lesson-description"
              maxlength="255"
              style="height: 0px; overflow: hidden;"
            >
              Description 1
            </textarea>
            <textarea
              aria-hidden="true"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiInputBase-inputSizeSmall emotion-6"
              readonly=""
              style="visibility: hidden; position: absolute; overflow: hidden; height: 0px; top: 0px; left: 0px; transform: translateZ(0); padding-top: 0px; padding-bottom: 0px; width: 100%;"
              tabindex="-1"
            />
            <fieldset
              aria-hidden="true"
              class="MuiOutlinedInput-notchedOutline emotion-8"
            >
              <legend
                class="emotion-9"
              >
                <span
                  class="notranslate"
                >
                  ​
                </span>
              </legend>
            </fieldset>
          </div>
        </div>
      </div>
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-10"
      />
      <div
        class="MuiStack-root emotion-2"
      >
        <span
          class="MuiTypography-root MuiTypography-labelMedium emotion-3"
        >
          Cover Image
        </span>
        <p
          class="MuiTypography-root MuiTypography-bodySmall emotion-22"
        >
          Upload an image. It will show up on your assignments.
        </p>
        <div
          class="MuiBox-root emotion-23"
        >
          <div
            class="MuiBox-root emotion-24"
            h="100%"
            w="100%"
          >
            <img
              alt="course collection thumbnail"
              data-testid="lesson-thumbnail"
              style="object-fit: cover; height: inherit; width: 100%;"
            />
          </div>
          <div
            class="MuiStack-root emotion-25"
          >
            <div
              class="MuiStack-root emotion-26"
            >
              <div
                class="MuiStack-root emotion-27"
              >
                <label
                  class="MuiButtonBase-root MuiButton-root MuiButton-raised MuiButton-raisedPrimary MuiButton-sizeMedium MuiButton-raisedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-raised MuiButton-raisedPrimary MuiButton-sizeMedium MuiButton-raisedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation upload-button emotion-28"
                  role="button"
                  tabindex="0"
                >
                  <span
                    class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-29"
                  >
                    <svg
                      aria-hidden="true"
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-30"
                      data-testid="UploadFileIcon"
                      focusable="false"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8zm4 18H6V4h7v5h5zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
                      />
                    </svg>
                  </span>
                  Replace file
                  <input
                    accept="image/png, image/jpeg, image/jpg"
                    class="emotion-31"
                    data-testid="file-uploader-input"
                    type="file"
                  />
                  <span
                    class="MuiTouchRipple-root emotion-32"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-10"
      />
      <div
        class="MuiBox-root emotion-34"
      >
        <button
          class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedError MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorError MuiButton-disableElevation MuiButton-root MuiButton-outlined MuiButton-outlinedError MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorError MuiButton-disableElevation emotion-35"
          data-testid="delete-button"
          tabindex="0"
          type="button"
        >
          <span
            class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-29"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-30"
              data-testid="DeleteIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
              />
            </svg>
          </span>
          Delete Lesson
          <span
            class="MuiTouchRipple-root emotion-32"
          />
        </button>
      </div>
    </div>
  </div>
  .emotion-0 {
  position: absolute;
  bottom: 0;
  width: calc(100% - undefinedpx);
  z-index: 1;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #FCFAFD;
}

.emotion-2 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-4 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.emotion-5 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 10px 12px;
}

.emotion-5::-moz-focus-inner {
  border-style: none;
}

.emotion-5.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-5 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-5:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
}

@media (hover: none) {
  .emotion-5:hover {
    background-color: transparent;
  }
}

.emotion-5.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-5:hover {
  box-shadow: none;
}

.emotion-5.Mui-focusVisible {
  box-shadow: none;
}

.emotion-5:active {
  box-shadow: none;
}

.emotion-5.Mui-disabled {
  box-shadow: none;
}

.emotion-6 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-7 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-7::-moz-focus-inner {
  border-style: none;
}

.emotion-7.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-7 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-7:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-7:hover {
    background-color: #4B35FF;
  }
}

.emotion-7:active {
  box-shadow: none;
}

.emotion-7.Mui-focusVisible {
  box-shadow: none;
}

.emotion-7.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-7:hover {
  box-shadow: none;
}

.emotion-7.Mui-focusVisible {
  box-shadow: none;
}

.emotion-7:active {
  box-shadow: none;
}

.emotion-7.Mui-disabled {
  box-shadow: none;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-2"
      />
      <div
        class="MuiStack-root emotion-3"
      >
        <div
          class="MuiBox-root emotion-4"
        />
        <button
          class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-5"
          tabindex="0"
          type="button"
        >
          Cancel
          <span
            class="MuiTouchRipple-root emotion-6"
          />
        </button>
        <button
          class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation Mui-disabled MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-7"
          data-testid="update-button"
          disabled=""
          tabindex="-1"
          type="button"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</DocumentFragment>
`;

exports[`GeneralSettings renders the thumbnail image and replace button 1`] = `
<DocumentFragment>
  .emotion-0 {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 80px;
  padding-top: 16px;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
}

.emotion-3 {
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.emotion-4 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
  background-color: #FFFFFF;
}

.emotion-5 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px 0 5px;
  padding-top: 1px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  padding: 8.5px 14px;
}

.emotion-5.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-5:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-5:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-5.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-5.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-5.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-6 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 0;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: auto;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 0;
  resize: none;
  padding: 0;
}

.emotion-6::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-6::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-6:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-6::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-6:focus {
  outline: 0;
}

.emotion-6:invalid {
  box-shadow: none;
}

.emotion-6::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-6:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-6.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-6:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-6:-webkit-autofill {
  border-radius: inherit;
}

.emotion-8 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-9 {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0;
  line-height: 11px;
  -webkit-transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-10 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

.emotion-22 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.emotion-23 {
  border: 1px solid;
  border-color: #D6D5DD;
  border-radius: 8px;
  width: 320px;
  height: 180px;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.emotion-23:hover .upload-button {
  opacity: 1;
}

.emotion-24 {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
}

.emotion-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-26 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
}

.emotion-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 600px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-28 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: #F1ECF4;
  color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
  min-width: 156px;
  opacity: 0;
}

.emotion-28::-moz-focus-inner {
  border-style: none;
}

.emotion-28.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-28 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-28:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(28, 27, 31, 0.04);
}

@media (hover: none) {
  .emotion-28:hover {
    background-color: transparent;
  }
}

.emotion-28.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-28:hover {
  box-shadow: none;
}

.emotion-28.Mui-focusVisible {
  box-shadow: none;
}

.emotion-28:active {
  box-shadow: none;
}

.emotion-28.Mui-disabled {
  box-shadow: none;
}

.emotion-28:hover,
.emotion-28:focus {
  background-color: #E3DFFF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
}

.emotion-28.Mui-disabled {
  background-color: #FCFAFD;
}

.emotion-29 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-29>*:nth-of-type(1) {
  font-size: 20px;
}

.emotion-30 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-31 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

.emotion-32 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-35 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(186, 26, 26, 0.5);
  color: #BA1A1A;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-35::-moz-focus-inner {
  border-style: none;
}

.emotion-35.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-35 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-35:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(186, 26, 26, 0.04);
  border: 1px solid #BA1A1A;
}

@media (hover: none) {
  .emotion-35:hover {
    background-color: transparent;
  }
}

.emotion-35.Mui-disabled {
  color: #1C1B1F61;
  border: 1px solid #1C1B1F1F;
}

.emotion-35:hover {
  box-shadow: none;
}

.emotion-35.Mui-focusVisible {
  box-shadow: none;
}

.emotion-35:active {
  box-shadow: none;
}

.emotion-35.Mui-disabled {
  box-shadow: none;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <label
          class="MuiTypography-root MuiTypography-labelMedium emotion-3"
          for="lesson-title"
        >
          Title
        </label>
        <div
          class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-4"
          data-testid="lesson-title"
        >
          <div
            class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall MuiInputBase-multiline emotion-5"
          >
            <textarea
              aria-invalid="false"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiInputBase-inputSizeSmall emotion-6"
              id="lesson-title"
              style="height: 0px; overflow: hidden;"
            >
              Lesson 1
            </textarea>
            <textarea
              aria-hidden="true"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiInputBase-inputSizeSmall emotion-6"
              readonly=""
              style="visibility: hidden; position: absolute; overflow: hidden; height: 0px; top: 0px; left: 0px; transform: translateZ(0); padding-top: 0px; padding-bottom: 0px; width: 100%;"
              tabindex="-1"
            />
            <fieldset
              aria-hidden="true"
              class="MuiOutlinedInput-notchedOutline emotion-8"
            >
              <legend
                class="emotion-9"
              >
                <span
                  class="notranslate"
                >
                  ​
                </span>
              </legend>
            </fieldset>
          </div>
        </div>
      </div>
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-10"
      />
      <div
        class="MuiStack-root emotion-2"
      >
        <label
          class="MuiTypography-root MuiTypography-labelMedium emotion-3"
          for="lesson-description"
        >
          Description
        </label>
        <div
          class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-4"
          data-testid="lesson-description"
        >
          <div
            class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall MuiInputBase-multiline emotion-5"
          >
            <textarea
              aria-invalid="false"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiInputBase-inputSizeSmall emotion-6"
              id="lesson-description"
              maxlength="255"
              style="height: 0px; overflow: hidden;"
            >
              Description 1
            </textarea>
            <textarea
              aria-hidden="true"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiInputBase-inputSizeSmall emotion-6"
              readonly=""
              style="visibility: hidden; position: absolute; overflow: hidden; height: 0px; top: 0px; left: 0px; transform: translateZ(0); padding-top: 0px; padding-bottom: 0px; width: 100%;"
              tabindex="-1"
            />
            <fieldset
              aria-hidden="true"
              class="MuiOutlinedInput-notchedOutline emotion-8"
            >
              <legend
                class="emotion-9"
              >
                <span
                  class="notranslate"
                >
                  ​
                </span>
              </legend>
            </fieldset>
          </div>
        </div>
      </div>
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-10"
      />
      <div
        class="MuiStack-root emotion-2"
      >
        <span
          class="MuiTypography-root MuiTypography-labelMedium emotion-3"
        >
          Cover Image
        </span>
        <p
          class="MuiTypography-root MuiTypography-bodySmall emotion-22"
        >
          Upload an image. It will show up on your assignments.
        </p>
        <div
          class="MuiBox-root emotion-23"
        >
          <div
            class="MuiBox-root emotion-24"
            h="100%"
            w="100%"
          >
            <img
              alt="course collection thumbnail"
              data-testid="lesson-thumbnail"
              style="object-fit: cover; height: inherit; width: 100%;"
            />
          </div>
          <div
            class="MuiStack-root emotion-25"
          >
            <div
              class="MuiStack-root emotion-26"
            >
              <div
                class="MuiStack-root emotion-27"
              >
                <label
                  class="MuiButtonBase-root MuiButton-root MuiButton-raised MuiButton-raisedPrimary MuiButton-sizeMedium MuiButton-raisedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-raised MuiButton-raisedPrimary MuiButton-sizeMedium MuiButton-raisedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation upload-button emotion-28"
                  role="button"
                  tabindex="0"
                >
                  <span
                    class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-29"
                  >
                    <svg
                      aria-hidden="true"
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-30"
                      data-testid="UploadFileIcon"
                      focusable="false"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8zm4 18H6V4h7v5h5zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
                      />
                    </svg>
                  </span>
                  Replace file
                  <input
                    accept="image/png, image/jpeg, image/jpg"
                    class="emotion-31"
                    data-testid="file-uploader-input"
                    type="file"
                  />
                  <span
                    class="MuiTouchRipple-root emotion-32"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-10"
      />
      <div
        class="MuiBox-root emotion-34"
      >
        <button
          class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedError MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorError MuiButton-disableElevation MuiButton-root MuiButton-outlined MuiButton-outlinedError MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorError MuiButton-disableElevation emotion-35"
          data-testid="delete-button"
          tabindex="0"
          type="button"
        >
          <span
            class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-29"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-30"
              data-testid="DeleteIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
              />
            </svg>
          </span>
          Delete Lesson
          <span
            class="MuiTouchRipple-root emotion-32"
          />
        </button>
      </div>
    </div>
  </div>
  .emotion-0 {
  position: absolute;
  bottom: 0;
  width: calc(100% - undefinedpx);
  z-index: 1;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #FCFAFD;
}

.emotion-2 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-4 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.emotion-5 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 10px 12px;
}

.emotion-5::-moz-focus-inner {
  border-style: none;
}

.emotion-5.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-5 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-5:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
}

@media (hover: none) {
  .emotion-5:hover {
    background-color: transparent;
  }
}

.emotion-5.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-5:hover {
  box-shadow: none;
}

.emotion-5.Mui-focusVisible {
  box-shadow: none;
}

.emotion-5:active {
  box-shadow: none;
}

.emotion-5.Mui-disabled {
  box-shadow: none;
}

.emotion-6 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-7 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-7::-moz-focus-inner {
  border-style: none;
}

.emotion-7.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-7 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-7:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-7:hover {
    background-color: #4B35FF;
  }
}

.emotion-7:active {
  box-shadow: none;
}

.emotion-7.Mui-focusVisible {
  box-shadow: none;
}

.emotion-7.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-7:hover {
  box-shadow: none;
}

.emotion-7.Mui-focusVisible {
  box-shadow: none;
}

.emotion-7:active {
  box-shadow: none;
}

.emotion-7.Mui-disabled {
  box-shadow: none;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-2"
      />
      <div
        class="MuiStack-root emotion-3"
      >
        <div
          class="MuiBox-root emotion-4"
        />
        <button
          class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-5"
          tabindex="0"
          type="button"
        >
          Cancel
          <span
            class="MuiTouchRipple-root emotion-6"
          />
        </button>
        <button
          class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation Mui-disabled MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-7"
          data-testid="update-button"
          disabled=""
          tabindex="-1"
          type="button"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
