import { useAppContext } from '../AppContext';
import { BackgroundTask } from '../../controllers/types';

// Lesson background task names
export const LessonBTName = {
  LESSON_PLANNING: `generate-entire-lesson`,
  LESSON_PUBLISHING: `publish-lesson`,
  LESSON_TRANSLATION: `translate-lesson`,
  LESSON_VIDEO_GENERATION: `generate-and-normalize-video-for-segment`,
  LESSON_VIDEO_REGENERATION: `regenerate-and-normalize-video-for-segment`,
};

type LessonProgressData = {
  activePlanningTasks: BackgroundTask[];
  failedPlanningTasks: BackgroundTask[];
  isPlanningInProgress: boolean;
};

export function useLessonBTWatcher(lessonId: string | number | undefined): LessonProgressData {
  const { notifications } = useAppContext();

  if (!lessonId) return { activePlanningTasks: [], failedPlanningTasks: [], isPlanningInProgress: false };

  const activePlanningTasks = selectLessonPlanningTasks(lessonId, notifications.backgroundTasks?.activeTasks || []);
  const failedPlanningTasks = selectLessonPlanningTasks(lessonId, notifications.backgroundTasks?.failedTasks || []);
  const isPlanningInProgress = activePlanningTasks.length > 0;

  // TODO(EGE): Placeholder for adding other task types we want to watch
  //  - publishing, translation, video generation, etc.

  return { activePlanningTasks, failedPlanningTasks, isPlanningInProgress };
}

// HELPERS

function selectLessonPlanningTasks(lessonId: string | number, tasks: BackgroundTask[]) {
  return tasks.filter(
    task =>
      task.task_entity_type === 'Lesson' &&
      task.task_entity_id &&
      task.task_entity_id.toString() === lessonId?.toString?.() &&
      task.name.includes(LessonBTName.LESSON_PLANNING),
  );
}
