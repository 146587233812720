import React, { useState } from 'react';
import { Box, Stack, TextField, Typography, Select, MenuItem, Button } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import { useGenerateEntireLesson, useStructureLearningObjectives } from 'controllers/react-query';
import { Row } from 'components/Row/Row';
import { useFeatures } from 'components/FeaturesContext';
import { capitalize } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ActiveStorageBlob } from '../../Uploaders/types';
import { ACCEPT_ALL_TEXT_FILES, FileUploader, UploadButton } from '../../Uploaders/FileUploader';
import { FileList } from '../../Uploaders/FileList';
import { FeelingLuckyButton } from '../../Studio/components/FeelingLuckyButton';
import OpenBook from '../../../assets/open-book.svg';
import Test from '../../../assets/test.svg';
import { LoadingIndicator } from '../../LoadingIndicator';
import { KyronEvents } from '../../utils/KyronEvents';
import { ValidationModal } from './ValidationModal';
import { useUserContext } from '../../UserContext';
import { GenerateQuestionsPayload } from '../../../controllers/react-query/lessonGenerationHooks';

const emptyForm: GenerateQuestionsPayload = {
  lessonTitle: '',
  lessonObjective: '',
  audience: '',
  structuredLearningObjectives: [],
  signedMaterialIds: [],
  questionType: null,
  activityTemplate: 'lesson',
  ttsVoiceId: 'alloy',
  intendedTime: 3600,
};

export type Props = {
  onCancel: () => void;
  hideAuxContentUpload?: boolean;
  hideLuckyButton?: boolean;
  initialFormValues?: { lessonTitle: string; lessonObjective: string; audience: string };
};

export function CreateLessonForm({ hideAuxContentUpload, hideLuckyButton, initialFormValues, onCancel }: Props) {
  const { show_template_dropdown: showTemplateDropdown } = useFeatures();
  const { new_plg_flow: isNewPlgFlow } = useFeatures();
  const shouldDisableContentIngestion = isNewPlgFlow; // TODO: remove when content ingestion is ready

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUserContext();

  const [structuredObjectives, setStructuredObjectives] = useState<string[]>([]);
  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const initialLessonPayload = { ...emptyForm, ...(initialFormValues || {}) };
  const [createLessonPayload, setCreateLessonPayload] = useState(initialLessonPayload);
  const [isUploadingAuxContent, setIsUploadingAuxContent] = useState(false);

  const {
    isPending: isStructuringObjectives,
    error: objectivesError,
    mutate: structureObjectives,
  } = useStructureLearningObjectives();
  const { isPending: isCreatingLesson, mutate: createLesson } = useGenerateEntireLesson();

  const handleAdditionalMaterialRemove = (signedIdToRemove: string) => {
    setCreateLessonPayload(prevParams => ({
      ...prevParams,
      signedMaterialIds: prevParams.signedMaterialIds.filter(signedId => signedId !== signedIdToRemove),
    }));
  };

  const handleAdditionalMaterialAttach = (blobs: ActiveStorageBlob[]) => {
    // create an array of signed_ids from the blobs, skip if signed_id is not present
    const newMaterialIds = blobs.reduce(
      (acc, curr) => (curr?.signed_id ? [...acc, curr.signed_id] : acc),
      [] as string[],
    );
    if (!newMaterialIds.length) return;

    KyronEvents.sendEvent(KyronEvents.names.UPLOAD_AUX_CONTENT, { lesson_title: createLessonPayload.lessonTitle });
    // new materials IDs should be appended to the existing ones
    setCreateLessonPayload(p => ({ ...p, signedMaterialIds: [...p.signedMaterialIds, ...newMaterialIds] }));
  };

  function handleCreateLesson() {
    createLesson(
      { payload: { ...createLessonPayload, structuredLearningObjectives: structuredObjectives } },
      {
        onSuccess: lesson => {
          navigate(`/studio/courses/${lesson.id}`);
          enqueueSnackbar(`Your course ${lesson.name} is being created. This may take a few minutes.`, {
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar(`Failed to create course ${createLessonPayload.lessonTitle}`, { variant: 'error' });
        },
      },
    );
  }

  function getStructuredObjectives() {
    structureObjectives(
      { payload: createLessonPayload },
      {
        onSuccess: objectives => {
          setStructuredObjectives(objectives);
        },
        onError: () => {
          enqueueSnackbar(`Failed to structure learning objectives for ${createLessonPayload.lessonTitle}`, {
            variant: 'error',
          });
        },
      },
    );
  }

  const approveButton = (
    <Button
      startIcon={isCreatingLesson ? <LoadingIndicator spinnerSize={20} /> : null}
      onClick={() => {
        handleCreateLesson();
        KyronEvents.sendEvent(KyronEvents.names.APPROVE_LEARNING_OBJECTIVES, {
          lesson_title: createLessonPayload.lessonTitle,
          user_id: user?.id || 'anonymous',
        });
      }}
      disabled={isStructuringObjectives || isCreatingLesson || isUploadingAuxContent}
    >
      Approve
    </Button>
  );

  return (
    <Stack sx={{ gap: 3, position: 'relative' }}>
      {!hideLuckyButton && (
        <Row sx={{ justifyContent: 'center' }}>
          <FeelingLuckyButton setCreateLessonPayload={setCreateLessonPayload} />
        </Row>
      )}
      {showTemplateDropdown && (
        <Stack spacing='2px'>
          <Typography variant='labelLarge' component='label' htmlFor='activity-template'>
            Activity Template
          </Typography>
          <Typography variant='bodyMedium' color='text.secondary'>
            What type of activity would you like to create?
          </Typography>
          <Select
            variant='outlined'
            value={createLessonPayload.activityTemplate}
            onChange={e =>
              setCreateLessonPayload(prevParams => ({ ...prevParams, activityTemplate: e.target.value as string }))
            }
            fullWidth
            required
            renderValue={value => capitalize(value)}
            id='activity-template'
          >
            <MenuItem value='lesson' sx={{ whiteSpace: 'wrap' }}>
              <Row gap={2} py={1} maxWidth={780}>
                <img src={OpenBook} alt='Lesson' width={40} />
                <Stack>
                  <Typography variant='labelLarge'>Lesson</Typography>
                  <Typography variant='bodyMedium' color='text.secondary'>
                    Teach learners new concepts through lectures, questions and discussions. Our AI-facilitated course
                    discussions encourage learners to think through open-ended questions.
                  </Typography>
                </Stack>
              </Row>
            </MenuItem>
            <MenuItem value='assessment' sx={{ whiteSpace: 'wrap' }}>
              <Row gap={2} py={1} maxWidth={780}>
                <img src={Test} alt='Assessment' width={40} />
                <Stack>
                  <Typography variant='labelLarge'>Assessment</Typography>
                  <Typography variant='bodyMedium' color='text.secondary'>
                    Test learners&apos; understanding with discussion-based assessments. Our AI-facilitated assessment
                    discussions gage learners&apos; understanding without guiding them to the correct answer.
                  </Typography>
                </Stack>
              </Row>
            </MenuItem>
          </Select>
        </Stack>
      )}
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-duration'>
          Duration
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          How long should this course take to complete?
        </Typography>
        <Select
          variant='outlined'
          value={createLessonPayload.intendedTime}
          onChange={e =>
            setCreateLessonPayload(prevParams => ({ ...prevParams, lessonDuration: e.target.value as string }))
          }
          fullWidth
          required
          id='lesson-duration'
        >
          <MenuItem value='3600'>1 hour</MenuItem>
          <MenuItem value='36000' disabled>
            1 week (10 hours) - Coming soon!
          </MenuItem>
          <MenuItem value='288000' disabled>
            8 weeks (80 hours) - Coming soon!
          </MenuItem>
          <MenuItem value='576000' disabled>
            16 weeks (160 hours) - Coming soon!
          </MenuItem>
        </Select>
      </Stack>
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-title'>
          Title
        </Typography>
        <TextField
          value={createLessonPayload.lessonTitle}
          onChange={e => setCreateLessonPayload(prevParams => ({ ...prevParams, lessonTitle: e.target.value }))}
          fullWidth
          placeholder='How to make the perfect cappuccino.'
          autoFocus
          required
          id='lesson-title'
        />
      </Stack>
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-audience'>
          Audience
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          Who is this course intended for?
        </Typography>
        <TextField
          value={createLessonPayload.audience}
          multiline
          minRows={3}
          onChange={e => setCreateLessonPayload(prevParams => ({ ...prevParams, audience: e.target.value }))}
          fullWidth
          required
          placeholder='Intelligent cats who live in single family homes, own an espresso machine, have opposable thumbs, and know the basics of making coffee.'
          id='lesson-audience'
        />
      </Stack>
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-objectives'>
          Learning Objectives
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          What should learners be able to do by the end of the course?
        </Typography>
        <TextField
          value={createLessonPayload.lessonObjective}
          multiline
          minRows={3}
          onChange={e => setCreateLessonPayload(prevParams => ({ ...prevParams, lessonObjective: e.target.value }))}
          fullWidth
          required
          placeholder='Students will know the best approaches to making a cappuccino, including coffee grind, type of milk, steaming techniques, and pouring techniques.'
          id='lesson-objectives'
        />
      </Stack>
      {!hideAuxContentUpload && (
        <Stack spacing='2px'>
          <Typography variant='labelLarge' component='label' htmlFor='lesson-objectives'>
            Upload additional materials
          </Typography>
          <Typography variant='bodyMedium' color='text.secondary'>
            Do you have additional learning context like a text file? Upload it to provide more context and make your
            course more relevant.
          </Typography>
          {shouldDisableContentIngestion ? (
            <Box pt={2}>
              <Button variant='outlined' disabled startIcon={<UploadFile />}>
                Coming Soon!
              </Button>
            </Box>
          ) : (
            <FileUploader
              acceptTypes={ACCEPT_ALL_TEXT_FILES}
              afterUpload={handleAdditionalMaterialAttach}
              onUploadStateChange={setIsUploadingAuxContent}
            >
              <FileList onRemove={handleAdditionalMaterialRemove} />
              <UploadButton />
            </FileUploader>
          )}
        </Stack>
      )}
      <Stack direction='row' justifyContent='space-between'>
        <Button variant='text' aria-label='cancel' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          aria-label='Create Course Button'
          startIcon={isCreatingLesson ? <LoadingIndicator spinnerSize={20} /> : null}
          onClick={() => {
            getStructuredObjectives();
            setValidationModalOpen(true);
            KyronEvents.sendEvent(KyronEvents.names.SUBMIT_CREATE_FORM, {
              lesson_title: createLessonPayload.lessonTitle,
              user_id: user?.id || 'anonymous',
            });
          }}
          disabled={
            !createLessonPayload.audience ||
            !createLessonPayload.lessonObjective ||
            !createLessonPayload.lessonTitle ||
            isCreatingLesson ||
            isUploadingAuxContent
          }
        >
          Create course
        </Button>
      </Stack>
      <ValidationModal
        open={validationModalOpen}
        onClose={() => setValidationModalOpen(false)}
        approveButton={approveButton}
        loading={isStructuringObjectives}
        error={objectivesError}
        objectives={structuredObjectives}
      />
    </Stack>
  );
}
