// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`OrganizationAnalytics renders error state 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.emotion-0 {
  padding: 24px;
}

.emotion-1 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.emotion-1>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-1>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-2 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1200px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1536px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

.emotion-3 {
  display: block;
  background-color: rgba(28, 27, 31, 0.11);
  height: 1.2em;
  border-radius: 8px;
  -webkit-animation: animation-0 2s ease-in-out 0.5s infinite;
  animation: animation-0 2s ease-in-out 0.5s infinite;
}

.emotion-4 {
  width: 100%;
}

.emotion-8 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:900px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1200px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

.emotion-21 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (min-width:600px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:900px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:1200px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:1536px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

.emotion-26 {
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 48px;
}

.emotion-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 200px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-28 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  width: 144px;
  height: 144px;
  background-color: #F1ECF4;
}

.emotion-29 {
  margin: 0;
}

.emotion-30 {
  margin-top: 8px;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
    >
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 emotion-2"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 75px;"
        />
      </div>
      <div
        class="MuiBox-root emotion-4"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 emotion-2"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 75px;"
        />
      </div>
      <div
        class="MuiBox-root emotion-4"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiBox-root emotion-4"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-2 emotion-21"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
    </div>
    <div
      class="MuiBox-root emotion-26"
    >
      <div
        class="MuiStack-root emotion-27"
      >
        <div
          class="MuiStack-root emotion-28"
        >
          <img
            alt=""
            src="SvgrURL"
            width="167"
          />
        </div>
        <span
          class="MuiTypography-root MuiTypography-body1 emotion-29"
        >
          Error loading tables data
        </span>
        <div
          class="MuiBox-root emotion-30"
        />
      </div>
    </div>
  </div>
</DocumentFragment>
`;

exports[`OrganizationAnalytics renders loading state 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.emotion-0 {
  padding: 24px;
}

.emotion-1 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.emotion-1>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-1>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-2 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1200px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1536px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

.emotion-3 {
  display: block;
  background-color: rgba(28, 27, 31, 0.11);
  height: 1.2em;
  border-radius: 8px;
  -webkit-animation: animation-0 2s ease-in-out 0.5s infinite;
  animation: animation-0 2s ease-in-out 0.5s infinite;
}

.emotion-4 {
  width: 100%;
}

.emotion-8 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:900px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1200px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

.emotion-21 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (min-width:600px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:900px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:1200px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:1536px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

.emotion-26 {
  box-sizing: border-box;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-27 {
  width: 100%;
  height: 425px;
  margin-top: 16px;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
    >
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 emotion-2"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 75px;"
        />
      </div>
      <div
        class="MuiBox-root emotion-4"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 emotion-2"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 75px;"
        />
      </div>
      <div
        class="MuiBox-root emotion-4"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiBox-root emotion-4"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-2 emotion-21"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
    </div>
    <div
      class="MuiGrid-root emotion-26"
    >
      <div
        class="MuiBox-root emotion-27"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 100%;"
        />
      </div>
      <div
        class="MuiBox-root emotion-27"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 100%;"
        />
      </div>
    </div>
  </div>
</DocumentFragment>
`;

exports[`OrganizationAnalytics renders no analytics data state 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.emotion-0 {
  padding: 24px;
}

.emotion-1 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.emotion-1>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-1>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-2 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1200px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1536px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

.emotion-3 {
  display: block;
  background-color: rgba(28, 27, 31, 0.11);
  height: 1.2em;
  border-radius: 8px;
  -webkit-animation: animation-0 2s ease-in-out 0.5s infinite;
  animation: animation-0 2s ease-in-out 0.5s infinite;
}

.emotion-4 {
  width: 100%;
}

.emotion-8 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:900px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1200px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-8 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

.emotion-21 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (min-width:600px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:900px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:1200px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:1536px) {
  .emotion-21 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

.emotion-26 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border: 1px solid #D6D5DD;
  background-image: none;
  border-radius: 24px;
  padding: 24px;
  margin-top: 32px;
}

.emotion-27 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
    >
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 emotion-2"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 75px;"
        />
      </div>
      <div
        class="MuiBox-root emotion-4"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 emotion-2"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 75px;"
        />
      </div>
      <div
        class="MuiBox-root emotion-4"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiBox-root emotion-4"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-2 emotion-21"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-8"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
    </div>
    <div
      class="MuiPaper-root MuiPaper-outlined MuiPaper-rounded emotion-26"
    >
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-27"
      >
        Lessons Taken and Completed Over Time
      </p>
      <div
        class="MuiBox-root emotion-4"
      >
        <div>
          Mocked LineChart
        </div>
      </div>
    </div>
    <div
      class="MuiPaper-root MuiPaper-outlined MuiPaper-rounded emotion-26"
    >
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-27"
      >
        Usage Throughout the Day
      </p>
      <div
        class="MuiBox-root emotion-4"
      >
        <div>
          Mocked LineChart
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;

exports[`OrganizationAnalytics renders the analytics data 1`] = `
<DocumentFragment>
  .emotion-0 {
  padding: 24px;
}

.emotion-1 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.emotion-1>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-1>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-2 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1200px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1536px) {
  .emotion-2 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

.emotion-3 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  width: 100%;
}

.emotion-3>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-3>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-4 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-4 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-4 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 25%;
  }
}

@media (min-width:1200px) {
  .emotion-4 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 25%;
  }
}

@media (min-width:1536px) {
  .emotion-4 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 25%;
  }
}

.emotion-5 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}

.emotion-5 .clearButton {
  opacity: 1;
}

@media (pointer: fine) {
  .emotion-5 .clearButton {
    opacity: 0;
  }

  .emotion-5:hover .clearButton,
  .emotion-5:focus-within .clearButton {
    opacity: 1;
  }
}

.emotion-6 {
  color: #1C1B1FB3;
  line-height: 1.4375em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.emotion-6.Mui-focused {
  color: #4B35FF;
}

.emotion-6.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-6.Mui-error {
  color: #BA1A1A;
}

.emotion-7 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  padding-right: 14px;
}

.emotion-7.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-7:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-7:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-7.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-7.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-7.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-8 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-right: 0;
}

.emotion-8::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8:focus {
  outline: 0;
}

.emotion-8:invalid {
  box-shadow: none;
}

.emotion-8::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-8.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-8:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-8:-webkit-autofill {
  border-radius: inherit;
}

.emotion-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0.01em;
  max-height: 2em;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

.emotion-10 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-right: -12px;
}

.emotion-10::-moz-focus-inner {
  border-style: none;
}

.emotion-10.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-10 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-10:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-10:hover {
    background-color: transparent;
  }
}

.emotion-10.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-11 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-12 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-13 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-14 {
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  white-space: nowrap;
}

.emotion-14>span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
}

.emotion-26 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (min-width:1200px) {
  .emotion-26 {
    -webkit-flex-basis: 8.333333%;
    -ms-flex-preferred-size: 8.333333%;
    flex-basis: 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 8.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-26 {
    -webkit-flex-basis: 8.333333%;
    -ms-flex-preferred-size: 8.333333%;
    flex-basis: 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 8.333333%;
  }
}

.emotion-27 {
  width: 100%;
}

.emotion-28 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-28 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-28 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1200px) {
  .emotion-28 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-28 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

.emotion-29 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 8px;
  box-shadow: none;
}

.emotion-29 .MuiButtonGroup-grouped {
  min-width: 40px;
}

.emotion-29 .MuiButtonGroup-firstButton,
.emotion-29 .MuiButtonGroup-middleButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent;
}

.emotion-29 .MuiButtonGroup-firstButton:hover,
.emotion-29 .MuiButtonGroup-middleButton:hover {
  border-right-color: currentColor;
}

.emotion-29 .MuiButtonGroup-lastButton,
.emotion-29 .MuiButtonGroup-middleButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.emotion-30 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 3px 9px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(75, 53, 255, 0.5);
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-30::-moz-focus-inner {
  border-style: none;
}

.emotion-30.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-30 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-30:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
  border: 1px solid #4B35FF;
}

@media (hover: none) {
  .emotion-30:hover {
    background-color: transparent;
  }
}

.emotion-30.Mui-disabled {
  color: #1C1B1F61;
  border: 1px solid #1C1B1F1F;
}

.emotion-30:hover {
  box-shadow: none;
}

.emotion-30.Mui-focusVisible {
  box-shadow: none;
}

.emotion-30:active {
  box-shadow: none;
}

.emotion-30.Mui-disabled {
  box-shadow: none;
}

.emotion-39 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-39 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-39 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:1200px) {
  .emotion-39 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:1536px) {
  .emotion-39 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

.emotion-40 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 8px;
}

.emotion-40.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-40:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-40:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-40.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-40.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-40.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-41 {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 8px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.emotion-41:focus {
  border-radius: 8px;
}

.emotion-41::-ms-expand {
  display: none;
}

.emotion-41.Mui-disabled {
  cursor: default;
}

.emotion-41[multiple] {
  height: auto;
}

.emotion-41:not([multiple]) option,
.emotion-41:not([multiple]) optgroup {
  background-color: #FCFAFD;
}

.emotion-41.emotion-41.emotion-41 {
  padding-right: 32px;
}

.emotion-41.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.emotion-41::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-41::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-41:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-41::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-41:focus {
  outline: 0;
}

.emotion-41:invalid {
  box-shadow: none;
}

.emotion-41::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-41::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-41::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-41:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-41::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-41:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-41:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-41:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-41:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-41.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-41:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-41:-webkit-autofill {
  border-radius: inherit;
}

.emotion-42 {
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
}

.emotion-43 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: absolute;
  right: 7px;
  top: calc(50% - .5em);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}

.emotion-43.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-45 {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0;
  line-height: 11px;
  -webkit-transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-47 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-47 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:900px) {
  .emotion-47 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1200px) {
  .emotion-47 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-47 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

.emotion-48 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  padding: 24px;
  text-align: center;
  color: #1C1B1FB3;
  background-color: #F1ECF4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: auto;
}

.emotion-48:hover {
  background-color: #F3DBF8;
}

.emotion-49 {
  padding: 16px;
}

.emotion-49:last-child {
  padding-bottom: 24px;
}

.emotion-51 {
  margin: 0;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
}

.emotion-52 {
  margin: 0;
}

.emotion-85 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (min-width:600px) {
  .emotion-85 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:900px) {
  .emotion-85 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:1200px) {
  .emotion-85 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:1536px) {
  .emotion-85 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

.emotion-98 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border: 1px solid #D6D5DD;
  background-image: none;
  border-radius: 24px;
  padding: 24px;
  margin-top: 32px;
}

.emotion-99 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
    >
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 emotion-2"
      >
        <div
          class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-3"
        >
          <div
            class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-3 MuiGrid-grid-lg-3 emotion-4"
          >
            <div
              class="MuiFormControl-root MuiTextField-root emotion-5"
            >
              <label
                class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined emotion-6"
                data-shrink="true"
                for=":r3:"
                id=":r3:-label"
              >
                Start Date
              </label>
              <div
                class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd emotion-7"
              >
                <input
                  aria-invalid="false"
                  autocomplete="off"
                  class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd emotion-8"
                  id=":r3:"
                  inputmode="text"
                  placeholder="MM/DD/YYYY hh:mm aa"
                  type="text"
                  value="10/01/2024 12:00 AM"
                />
                <div
                  class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium emotion-9"
                >
                  <button
                    aria-label="Choose date, selected date is Oct 1, 2024"
                    class="MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium emotion-10"
                    tabindex="0"
                    type="button"
                  >
                    <svg
                      aria-hidden="true"
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
                      data-testid="CalendarIcon"
                      focusable="false"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
                      />
                    </svg>
                    <span
                      class="MuiTouchRipple-root emotion-12"
                    />
                  </button>
                </div>
                <fieldset
                  aria-hidden="true"
                  class="MuiOutlinedInput-notchedOutline emotion-13"
                >
                  <legend
                    class="emotion-14"
                  >
                    <span>
                      Start Date
                    </span>
                  </legend>
                </fieldset>
              </div>
            </div>
          </div>
          <div
            class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-3 MuiGrid-grid-lg-3 emotion-4"
          >
            <div
              class="MuiFormControl-root MuiTextField-root emotion-5"
            >
              <label
                class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined emotion-6"
                data-shrink="true"
                for=":r5:"
                id=":r5:-label"
              >
                End Date
              </label>
              <div
                class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd emotion-7"
              >
                <input
                  aria-invalid="false"
                  autocomplete="off"
                  class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd emotion-8"
                  id=":r5:"
                  inputmode="text"
                  placeholder="MM/DD/YYYY hh:mm aa"
                  type="text"
                  value="07/25/2024 11:59 PM"
                />
                <div
                  class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium emotion-9"
                >
                  <button
                    aria-label="Choose date, selected date is Jul 25, 2024"
                    class="MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium emotion-10"
                    tabindex="0"
                    type="button"
                  >
                    <svg
                      aria-hidden="true"
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
                      data-testid="CalendarIcon"
                      focusable="false"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
                      />
                    </svg>
                    <span
                      class="MuiTouchRipple-root emotion-12"
                    />
                  </button>
                </div>
                <fieldset
                  aria-hidden="true"
                  class="MuiOutlinedInput-notchedOutline emotion-13"
                >
                  <legend
                    class="emotion-14"
                  >
                    <span>
                      End Date
                    </span>
                  </legend>
                </fieldset>
              </div>
            </div>
          </div>
          <div
            class="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-1 emotion-26"
          >
            <div
              class="MuiBox-root emotion-27"
            />
          </div>
          <div
            class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-4 emotion-28"
          >
            <div
              class="MuiButtonGroup-root MuiButtonGroup-outlined MuiButtonGroup-disableElevation emotion-29"
              data-testid="shortcut-buttons"
              role="group"
            >
              <button
                class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButtonGroup-firstButton emotion-30"
                name="Last Week"
                tabindex="0"
                type="button"
              >
                Last Week
                <span
                  class="MuiTouchRipple-root emotion-12"
                />
              </button>
              <button
                class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButtonGroup-middleButton emotion-30"
                name="This Month"
                tabindex="0"
                type="button"
              >
                This Month
                <span
                  class="MuiTouchRipple-root emotion-12"
                />
              </button>
              <button
                class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButtonGroup-middleButton emotion-30"
                name="This Year"
                tabindex="0"
                type="button"
              >
                This Year
                <span
                  class="MuiTouchRipple-root emotion-12"
                />
              </button>
              <button
                class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButtonGroup-lastButton emotion-30"
                name="All Time"
                tabindex="0"
                type="button"
              >
                All Time
                <span
                  class="MuiTouchRipple-root emotion-12"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiBox-root emotion-27"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6 emotion-39"
      >
        <div
          class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth  emotion-40"
        >
          <div
            aria-controls=":r7:"
            aria-expanded="false"
            aria-haspopup="listbox"
            class="MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input emotion-41"
            role="combobox"
            tabindex="0"
          >
            All lessons
          </div>
          <input
            aria-hidden="true"
            aria-invalid="false"
            class="MuiSelect-nativeInput emotion-42"
            tabindex="-1"
            value=""
          />
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined emotion-43"
            data-testid="ArrowDropDownIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M7 10l5 5 5-5z"
            />
          </svg>
          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline emotion-13"
          >
            <legend
              class="emotion-45"
            >
              <span
                class="notranslate"
              >
                ​
              </span>
            </legend>
          </fieldset>
        </div>
      </div>
      <div
        class="MuiBox-root emotion-27"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-47"
      >
        <div
          class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-48"
        >
          <div
            class="MuiCardContent-root emotion-49"
          >
            <div
              class="MuiBox-root emotion-50"
            >
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-51"
              >
                10
              </p>
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-52"
              >
                Members
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-47"
      >
        <div
          class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-48"
        >
          <div
            class="MuiCardContent-root emotion-49"
          >
            <div
              class="MuiBox-root emotion-50"
            >
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-51"
              >
                5
              </p>
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-52"
              >
                Active Lessons
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-47"
      >
        <div
          class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-48"
        >
          <div
            class="MuiCardContent-root emotion-49"
          >
            <div
              class="MuiBox-root emotion-50"
            >
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-51"
              >
                3
              </p>
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-52"
              >
                Lesson Creators
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiBox-root emotion-27"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-47"
      >
        <div
          class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-48"
        >
          <div
            class="MuiCardContent-root emotion-49"
          >
            <div
              class="MuiBox-root emotion-50"
            >
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-51"
              >
                10
              </p>
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-52"
              >
                Lessons Completed
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-47"
      >
        <div
          class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-48"
        >
          <div
            class="MuiCardContent-root emotion-49"
          >
            <div
              class="MuiBox-root emotion-50"
            >
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-51"
              >
                20
              </p>
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-52"
              >
                Lessons Attempted
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-47"
      >
        <div
          class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-48"
        >
          <div
            class="MuiCardContent-root emotion-49"
          >
            <div
              class="MuiBox-root emotion-50"
            >
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-51"
              >
                50
              </p>
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-52"
              >
                Lesson Completion %
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiBox-root emotion-27"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-2 emotion-85"
      />
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-47"
      >
        <div
          class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-48"
        >
          <div
            class="MuiCardContent-root emotion-49"
          >
            <div
              class="MuiBox-root emotion-50"
            >
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-51"
              >
                5
              </p>
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-52"
              >
                Unique LTI Players
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 emotion-47"
      >
        <div
          class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-48"
        >
          <div
            class="MuiCardContent-root emotion-49"
          >
            <div
              class="MuiBox-root emotion-50"
            >
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-51"
              >
                15
              </p>
              <p
                class="MuiTypography-root MuiTypography-body1 emotion-52"
              >
                Unique Kyron Players
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="MuiPaper-root MuiPaper-outlined MuiPaper-rounded emotion-98"
    >
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-99"
      >
        Lessons Taken and Completed Over Time
      </p>
      <div
        class="MuiBox-root emotion-27"
      >
        <div>
          Mocked LineChart
        </div>
      </div>
    </div>
    <div
      class="MuiPaper-root MuiPaper-outlined MuiPaper-rounded emotion-98"
    >
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-99"
      >
        Usage Throughout the Day
      </p>
      <div
        class="MuiBox-root emotion-27"
      >
        <div>
          Mocked LineChart
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
