import React from 'react';
import { Outlet } from 'react-router-dom';
import { useCurrentUser } from 'controllers/react-query/userHooks';
import { Typography } from '@mui/material';
import { UserProvider, makeUserContext } from './UserContext';
import { useHandleUpdateIntercom } from './utils/KyronIntercom';
import { ModalProvider } from './utils/ModalContext';
import { LoadingIndicator } from './LoadingIndicator';
import { AppContext, useInitAppContext } from './AppContext';

const RouterTestingLayer = ({ children }: { children: React.ReactNode }) => (
  <div data-testid='kyron-app'>{children}</div>
);

/**
 * Note about this component:
 *
 * For some context to work and be propagated to the rest of the app properly, children need
 * to be in one lower level than the context providers. App component is to achieve that.
 * If you providers doesn't need to wrap the Router, then, it's better you keep them here
 * rather than in Main.tsx
 */

export function App() {
  useHandleUpdateIntercom();

  const { isFetching, isError, data: currentUser } = useCurrentUser();

  const appContext = useInitAppContext();

  if (isFetching) return <LoadingIndicator data-testid='loading-indicator' />;
  if (isError) return <Typography variant='titleSmall'>Error loading user.</Typography>;
  return (
    <RouterTestingLayer>
      {/* Uncomment this line to debug ReactQuery */}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <UserProvider value={makeUserContext(currentUser)}>
        <AppContext.Provider value={appContext}>
          <ModalProvider>
            <Outlet />
          </ModalProvider>
        </AppContext.Provider>
      </UserProvider>
    </RouterTestingLayer>
  );
}
