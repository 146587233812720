// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Branding renders correctly should render the component with default values 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
}

.emotion-0>:not(style):not(style) {
  margin: 0;
}

.emotion-0>:not(style)~:not(style) {
  margin-top: 16px;
}

.emotion-1 {
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.emotion-2 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #1C1B1FB3;
}

.emotion-3 {
  position: relative;
  display: inline-block;
  width: 320px;
  height: 180px;
  padding: 8px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid;
}

.emotion-4 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.emotion-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
}

.emotion-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 600px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-7 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: #F1ECF4;
  color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
  min-width: 156px;
  margin-bottom: 5px;
}

.emotion-7::-moz-focus-inner {
  border-style: none;
}

.emotion-7.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-7 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-7:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(28, 27, 31, 0.04);
}

@media (hover: none) {
  .emotion-7:hover {
    background-color: transparent;
  }
}

.emotion-7.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-7:hover {
  box-shadow: none;
}

.emotion-7.Mui-focusVisible {
  box-shadow: none;
}

.emotion-7:active {
  box-shadow: none;
}

.emotion-7.Mui-disabled {
  box-shadow: none;
}

.emotion-7:hover,
.emotion-7:focus {
  background-color: #E3DFFF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
}

.emotion-7.Mui-disabled {
  background-color: #FCFAFD;
}

.emotion-8 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-8>*:nth-of-type(1) {
  font-size: 20px;
}

.emotion-9 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-10 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

.emotion-11 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<div
    class="MuiStack-root emotion-0"
  >
    <span
      class="MuiTypography-root MuiTypography-labelMedium emotion-1"
    >
      Generated Avatar Slide Background
    </span>
    <p
      class="MuiTypography-root MuiTypography-bodySmall emotion-2"
    >
      You can provide your own background image when you use generated avatar slides. Only PNG, JPG, and JPEG files with 1920x1080 resolution are supported.
    </p>
    <div
      class="MuiBox-root emotion-3"
    >
      <img
        alt="Default Image"
        src="SvgrURL"
        style="display: block; width: 100%; height: 100%;"
      />
      <div
        class="MuiBox-root emotion-4"
      >
        <div
          class="MuiStack-root emotion-5"
        >
          <div
            class="MuiStack-root emotion-6"
          >
            <label
              class="MuiButtonBase-root MuiButton-root MuiButton-raised MuiButton-raisedPrimary MuiButton-sizeMedium MuiButton-raisedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-raised MuiButton-raisedPrimary MuiButton-sizeMedium MuiButton-raisedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation upload-button emotion-7"
              role="button"
              tabindex="0"
            >
              <span
                class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-8"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                  data-testid="UploadFileIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8zm4 18H6V4h7v5h5zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
                  />
                </svg>
              </span>
              Upload image
              <input
                accept="image/png, image/jpg, image/jpeg"
                class="emotion-10"
                data-testid="file-uploader-input"
                type="file"
              />
              <span
                class="MuiTouchRipple-root emotion-11"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;

exports[`Branding renders correctly with an existing image should display 2 buttons when an image file is already uploaded 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
}

.emotion-0>:not(style):not(style) {
  margin: 0;
}

.emotion-0>:not(style)~:not(style) {
  margin-top: 16px;
}

.emotion-1 {
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.emotion-2 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #1C1B1FB3;
}

.emotion-3 {
  position: relative;
  display: inline-block;
  width: 320px;
  height: 180px;
  padding: 8px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid;
}

.emotion-4 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.emotion-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
}

.emotion-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 600px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-7 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: #F1ECF4;
  color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
  min-width: 156px;
  margin-bottom: 5px;
}

.emotion-7::-moz-focus-inner {
  border-style: none;
}

.emotion-7.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-7 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-7:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(28, 27, 31, 0.04);
}

@media (hover: none) {
  .emotion-7:hover {
    background-color: transparent;
  }
}

.emotion-7.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-7:hover {
  box-shadow: none;
}

.emotion-7.Mui-focusVisible {
  box-shadow: none;
}

.emotion-7:active {
  box-shadow: none;
}

.emotion-7.Mui-disabled {
  box-shadow: none;
}

.emotion-7:hover,
.emotion-7:focus {
  background-color: #E3DFFF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
}

.emotion-7.Mui-disabled {
  background-color: #FCFAFD;
}

.emotion-8 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-8>*:nth-of-type(1) {
  font-size: 20px;
}

.emotion-9 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-10 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

.emotion-11 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-12 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: #F1ECF4;
  color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
  color: #BA1A1A;
  max-width: 156px;
}

.emotion-12::-moz-focus-inner {
  border-style: none;
}

.emotion-12.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-12 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-12:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(28, 27, 31, 0.04);
}

@media (hover: none) {
  .emotion-12:hover {
    background-color: transparent;
  }
}

.emotion-12.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-12:hover {
  box-shadow: none;
}

.emotion-12.Mui-focusVisible {
  box-shadow: none;
}

.emotion-12:active {
  box-shadow: none;
}

.emotion-12.Mui-disabled {
  box-shadow: none;
}

.emotion-12:hover,
.emotion-12:focus {
  background-color: #E3DFFF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
}

.emotion-12:hover,
.emotion-12:focus {
  background-color: #FFDAD6;
}

<div
    class="MuiStack-root emotion-0"
  >
    <span
      class="MuiTypography-root MuiTypography-labelMedium emotion-1"
    >
      Generated Avatar Slide Background
    </span>
    <p
      class="MuiTypography-root MuiTypography-bodySmall emotion-2"
    >
      You can provide your own background image when you use generated avatar slides. Only PNG, JPG, and JPEG files with 1920x1080 resolution are supported.
    </p>
    <div
      class="MuiBox-root emotion-3"
    >
      <img
        alt="Custom Image"
        src="http://foo.bar"
        style="display: block; width: 100%; height: 100%;"
      />
      <div
        class="MuiBox-root emotion-4"
      >
        <div
          class="MuiStack-root emotion-5"
        >
          <div
            class="MuiStack-root emotion-6"
          >
            <label
              class="MuiButtonBase-root MuiButton-root MuiButton-raised MuiButton-raisedPrimary MuiButton-sizeMedium MuiButton-raisedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-raised MuiButton-raisedPrimary MuiButton-sizeMedium MuiButton-raisedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation upload-button emotion-7"
              role="button"
              tabindex="0"
            >
              <span
                class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-8"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                  data-testid="UploadFileIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8zm4 18H6V4h7v5h5zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
                  />
                </svg>
              </span>
              Replace image
              <input
                accept="image/png, image/jpg, image/jpeg"
                class="emotion-10"
                data-testid="file-uploader-input"
                type="file"
              />
              <span
                class="MuiTouchRipple-root emotion-11"
              />
            </label>
          </div>
          <button
            class="MuiButtonBase-root MuiButton-root MuiButton-raised MuiButton-raisedError MuiButton-sizeMedium MuiButton-raisedSizeMedium MuiButton-colorError MuiButton-disableElevation MuiButton-root MuiButton-raised MuiButton-raisedError MuiButton-sizeMedium MuiButton-raisedSizeMedium MuiButton-colorError MuiButton-disableElevation emotion-12"
            data-testid="file-uploader-remove-button"
            tabindex="0"
            type="button"
          >
            <span
              class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-8"
            >
              <svg
                aria-hidden="true"
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                data-testid="DeleteOutlineIcon"
                focusable="false"
                viewBox="0 0 24 24"
              >
                <path
                  d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM8 9h8v10H8zm7.5-5-1-1h-5l-1 1H5v2h14V4z"
                />
              </svg>
            </span>
            Remove image
            <span
              class="MuiTouchRipple-root emotion-11"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
