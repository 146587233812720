import { KyronClient } from './KyronClient';
import { KyronEvents } from './KyronEvents';

export function logOut() {
  KyronEvents.sendEvent(KyronEvents.names.LOGOUT);
  const client = new KyronClient();
  client.deleteData('/users/sign_out').then(() => {
    window.location.replace('/');
  });
}
