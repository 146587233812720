import { Box, IconButton, List, Popover, Stack, SxProps, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Error, UnfoldMore } from '@mui/icons-material';
import { Row } from 'components/Row/Row';
import { OrganizationSelectButton } from 'components/OrganizationSelectButton/OrganizationSelectButton';
import { useSnackbar } from 'notistack';
import { OrganizationSelectItem } from 'components/OrganizationSelectItem/OrganizationSelectItem';
import { SearchField } from 'components/SearchField';
import { OrganizationSelectNewOrg } from 'components/OrganizationSelectNewOrg/OrganizationSelectNewOrg';
import { navItemButtonColorStyle } from 'components/NavItemButton/NavItemButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { KyronImage } from 'components/KyronImage/KyronImage';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { Organization } from '../../controllers/types';
import { useGetOrganizationsInfinite, useSetActiveOrg } from '../../controllers/react-query/organizationHooks';
import { UserContext } from '../UserContext';
import { KyronTooltip } from '../KyronTooltip';

type Props = {
  sx?: SxProps;
  isIconButton?: boolean;
};

export function OrganizationSelect({ sx = null, isIconButton = false }: Props) {
  // tools
  const { user, isPlatformAdmin } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  // state
  const [activeOrg, setActiveOrg] = useState<Organization | null>(user?.active_organization || null);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const open = Boolean(anchorEl);
  const [search, setSearch] = useState('');

  // data
  const perPage = 5;
  const { data, isFetching, isRefetching, fetchNextPage, hasNextPage, isError } = useGetOrganizationsInfinite({
    perPage,
    searchText: search,
  });
  const shownDataLength = (data?.pageParams?.length || 0) * perPage;
  const totalDataLength = data?.pages?.[0]?.meta?.total_count || 0;

  // If there is not a search done by the user,
  // and if we are not showing all the organizations
  // we show the search bar
  const showSearchBar = Boolean(search) || totalDataLength > perPage;

  useEffect(updateActiveOrgWhenOrgChanges, [user?.active_organization]);
  function updateActiveOrgWhenOrgChanges() {
    // If a user makes changes to properties of the their active organization (name, etc.),
    // we need to update the intneral activeOrg state to reflect that change and trigger a re-render
    setActiveOrg(user?.active_organization || null);
  }

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSearch('');
  };

  const { mutate } = useSetActiveOrg();
  function setOrganization(organization: Organization) {
    closeMenu();
    setActiveOrg(organization);
    mutate(organization.id, {
      onSuccess: () => {
        // Changing organizations fundamentally changes the data we load on
        // most non-student pages. Rather than having all components that fetch
        // data listen to a hypothetical "organization change" event, we just
        // reload the page.
        // ---
        // UPDATE(ege): Once the user updating on active org change is implemented properly, this line will be removed
        window.location.reload();
      },
      onError: () => {
        enqueueSnackbar('Failed to set active organization', { variant: 'error' });
      },
    });
  }

  if (!activeOrg) return null;

  const orgAvatar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 32,
        borderRadius: '100px',
        bgcolor: 'surfaceContainer.main',
        flexGrow: 0,
        flexShrink: 0,
        overflow: 'hidden',
      }}
    >
      {activeOrg.logo ? (
        <KyronImage stretch src={activeOrg.logo} alt={activeOrg.name} loading='lazy' />
      ) : (
        <KyronAvatar label={activeOrg.name} />
      )}
    </Box>
  );

  return (
    <>
      {isIconButton ? (
        <KyronTooltip title='Change Organization' placement='right' arrow>
          <IconButton
            sx={{ width: '56px', height: '56px', ...navItemButtonColorStyle, ...sx }}
            data-testid='organization-select-icon-button'
            onClick={openMenu}
            aria-controls={open ? 'org-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            {orgAvatar}
          </IconButton>
        </KyronTooltip>
      ) : (
        <OrganizationSelectButton
          sx={sx}
          data-testid='organization-select'
          onClick={openMenu}
          aria-controls={open ? 'org-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          endIcon={<UnfoldMore fontSize='small' />}
          size='small'
        >
          {orgAvatar}
          <Typography variant='labelMedium' noWrap flex={1} textAlign='left'>
            {activeOrg?.name}
          </Typography>
        </OrganizationSelectButton>
      )}
      <Popover
        data-testid='org-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        slotProps={{ paper: { sx: { width: 400 } } }}
        sx={{ mt: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack>
          {showSearchBar ? (
            <Box sx={{ p: 1, borderBottom: '1px solid gray', borderBottomColor: 'divider' }}>
              <SearchField
                dataTestID='org-search'
                onSearch={setSearch}
                placeholder='Search organization'
                fullWidth
                autoFocus
                isLoading={isFetching || isRefetching}
              />
            </Box>
          ) : null}
          <List data-testid='org-menu-list' disablePadding sx={{ maxHeight: 255, overflowY: 'auto' }} id='org-list'>
            {isError && (
              <Row gap={1} sx={{ px: 2, height: 40 }} justifyContent='center'>
                <Error color='error' />
                <Typography variant='bodyMedium' color='error'>
                  Error fetching organizations
                </Typography>
              </Row>
            )}
            <InfiniteScroll
              scrollableTarget='org-list'
              dataLength={shownDataLength}
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={getInfiniteScrollFeedbackText('Loading organizations...')}
              endMessage={totalDataLength === 0 ? getInfiniteScrollFeedbackText(`No organizations found`) : null}
            >
              {data?.pages.map(organizationPage =>
                organizationPage.organizations.map(organization => (
                  <OrganizationSelectItem
                    active={activeOrg.id === organization.id}
                    organization={organization}
                    onClick={() => setOrganization(organization)}
                    key={organization.id}
                  />
                )),
              )}
            </InfiniteScroll>
          </List>
          {isPlatformAdmin && <OrganizationSelectNewOrg />}
        </Stack>
      </Popover>
    </>
  );
}

function getInfiniteScrollFeedbackText(text: string) {
  return (
    <Row sx={{ justifyContent: 'center', mt: 1 }}>
      <Typography variant='bodySmall' color='info.main'>
        {text}
      </Typography>
    </Row>
  );
}
