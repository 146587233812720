import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, DialogActions, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import { usePublishLesson, useLessonValidateQuery } from 'controllers/react-query';
import { PublishVisibility } from 'controllers/types';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ValidationsDisplay, ValidationErrors } from './ValidationsDisplay';

type VisibilityOptionType = {
  id: number;
  type: PublishVisibility;
  icon: JSX.Element;
  title: string;
  description: string;
};

const VisibilityOptions: VisibilityOptionType[] = [
  {
    id: 1,
    type: PublishVisibility.PUBLIC,
    icon: <LockOpenOutlined />,
    title: 'Public',
    description:
      'The course is available publicly in the Marketplace and in your public Channel. You and anyone else can assign this course.',
  },
  {
    id: 2,
    type: PublishVisibility.PRIVATE,
    icon: <LockOutlined />,
    title: 'Private',
    description: 'The course is available to you and members of your organization to share and assign.',
  },
];

function PublishVisibilityOptions({
  visibility,
  onUpdate,
}: {
  visibility: PublishVisibility;
  onUpdate: (visibility: PublishVisibility) => void;
}) {
  return (
    <Stack gap={2}>
      {VisibilityOptions.map((option: VisibilityOptionType) => (
        <Grid2
          key={option.id}
          container
          padding={2}
          columnSpacing={2}
          onClick={() => onUpdate(option.type)}
          sx={{
            border: 1,
            borderRadius: 1,
            borderColor: 'divider',
            '&:hover': {
              cursor: 'pointer',
              opacity: 0.6,
              bgcolor: 'primaryContainer.main',
            },
            bgcolor: visibility === option.type ? 'primaryContainer.main' : 'default',
          }}
        >
          <Grid2 xs={1} display='flex' alignItems='center' justifyContent='center' color='primary.main'>
            {option.icon}
          </Grid2>
          <Grid2 xs={11} display='flex'>
            <Stack>
              <Typography variant='titleSmall'>{option.title}</Typography>
              <Typography variant='bodyMedium'>{option.description}</Typography>
            </Stack>
          </Grid2>
        </Grid2>
      ))}
    </Stack>
  );
}

export function PublicPrivatePublishDialog({ lessonId, onClose }: { lessonId?: number; onClose: () => void }) {
  const {
    data: lessonValidations,
    refetch: refetchValidations,
    isFetching: loadingValidations,
    error: fetchValidationError,
  } = useLessonValidateQuery(lessonId);
  const { isPending: isPublishPending, error: publishLessonError, mutate: publishLesson } = usePublishLesson();
  const [visibility, setVisibility] = useState<PublishVisibility>(PublishVisibility.PUBLIC);

  function publishAndCloseDialog() {
    if (lessonId) {
      publishLesson(
        { lessonId, payload: { visibility } },
        {
          onSuccess: _data => {
            onClose();
          },
        },
      );
    }
  }

  const failingValidations =
    lessonValidations?.filter(
      validation => !validation.pass && validation.name !== ValidationErrors.UNNORMALIZED_VIDEOS,
    ) || [];
  const hasFailingValidations = failingValidations.length > 0;
  useEffect(() => {
    refetchValidations?.();
  }, [refetchValidations]);

  return (
    <>
      <Typography variant='bodyMedium' mb={2}>
        Determine who can see and assign this course. You can change this course’s visibility at any time from Settings.
      </Typography>
      <PublishVisibilityOptions visibility={visibility} onUpdate={setVisibility} />

      {fetchValidationError && (
        <Box mt={2}>
          <Alert severity='error'>{fetchValidationError.message}</Alert>
        </Box>
      )}

      {publishLessonError && (
        <Box mt={2}>
          <Alert severity='error'>{publishLessonError.message}</Alert>
        </Box>
      )}

      <ValidationsDisplay lessonId={lessonId} failingValidations={failingValidations} />

      <DialogActions sx={{ pr: 0, pb: 1 }}>
        <Button variant='outlined' disabled={isPublishPending} onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={publishAndCloseDialog}
          disabled={isPublishPending || loadingValidations || hasFailingValidations}
          startIcon={isPublishPending || loadingValidations ? <LoadingIndicator spinnerSize={20} /> : null}
        >
          Publish
        </Button>
      </DialogActions>
    </>
  );
}
